// import "antd/dist/antd.css";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Cascader,
  Switch,
  InputNumber,
  List,
  Divider,
  Table,
  Tag,
  Row,
  Col,
} from "antd";
import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EMPTY_OUTLET } from "../../../../utils/outlets";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/select";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Collapse } from "antd";
import useApiPost from "../../../../hooks/useApiPost";
import { SheetDataSet } from "./outlet-list-types";
import readXlsxFile from "read-excel-file";
import { toast } from "react-toastify";
import DropOffViewButton from "../../../../common/DropOffViewButton/DropOffViewButton";
const { Panel } = Collapse;

const containerStyle = {
  width: "100%",
  height: "100%",
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const UpdateOutlet = () => {
  const { request, setError } = useApiPost();
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useContext(AppContext);
  const { outletId } = useParams<{ outletId: string }>();
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const [outletStates, setOutletStates] = useState<any[]>([]);
  const [chains, setChains] = useState<any[]>([]);
  const [chainsProjectSpecific, setChainsProjectSpecific] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [generatorOptions, setAllGeneratorOptions] = useState<any[]>([]);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [allCities, setAllCities] = useState<any[]>([]);
  const [allGradings, setAllGradings] = useState<any[]>([]);
  const [rcIdsWithError, setRcIdsWithError] = useState<any[]>([]);
  const [dataError, setDataError] = useState<string>();
  const [reasonData, setReasonData] = useState<any[]>([]);
  const [currentZoom, setCurrentZoom] = useState<number | undefined>(18);
  const isAdd = !outletId;
  const mapRef = useRef<any>(null);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [newEntries, setNewEntries] = useState<any[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    const getAllData = async () => {
      setIsLoading(true);
      // const options = await getCreationOptions(projectId);
      const options = await request("/office/outlets/options", "POST", {
        projectId,
      });
      const {
        outletTypes,
        chainsAll,
        chainsProject,
        outletStates,
        geography,
        userOptions,
        calltypes,
        cities,
        gradings,
        generatorOptions,
      } = options;
      setOutletStates(outletStates);
      setOutletTypes(outletTypes);
      setAllUsers(userOptions);
      setAllCallTypes(calltypes);
      setAllCities(cities);
      setAllGradings(gradings);
      setAllGeneratorOptions(generatorOptions);
      // setChannels(channels);
      setChains(chainsAll);
      setChainsProjectSpecific(chainsProject);
      setTreeData(geography);
      // setChains(chains);
      if (outletId) {
        const data = await request("/office/outlets", "POST", {
          projectId,
          outletId,
        });
        setForm({
          ...form,
          ...data,
          // callType_id: data.callTypes,
          chain_id: data.chain_id,
          // users_id: data.connectedUsers
          //   ? data.connectedUsers.map((u: any) => u.value)
          //   : [],
          callType_id: data.connectedCallTypes
            ? data.connectedCallTypes.map((u: any) => u.value)
            : [],
          // hierarchy_id
        });
        // const data = outlets.map((e: any, index: number) => ({
        //   ...e,
        //   key: index,
        // }));
      }
      setIsLoading(false);
    };
    getAllData();
    !data?.isDropOff && reasonGet()
  }, [projectId, data]);

  const [form, setForm] = useState(EMPTY_OUTLET);


  const handleComeBack = () => {
    navigate("/admin/outlets");
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [, setResultInfo] = useState<string | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });
  const onUnmount = useCallback(function callback(map: any) { }, []);


  const reasonGet = useCallback(async () => {
    const response = await request("/office/dropoffReasons?isActive=true",  "GET",
      null,
      undefined,
      "v2");
    if (response?.status === 200) {
      setReasonData(response?.data)
    }
  }, [])

  const handleUploadBatchFile = async (e: any) => {
    const file = e.target.files[0];
    await readXlsxFile(file).then(async (rows: any) => {
      let filteredData: any = [];
      const encounteredCombinations = new Set();

      rows.forEach((item: any, index: number) => {
        if (index > 0) {
          const rc_id = item[0]?.toString().trim();
          const lAccount = item[1]?.toString().trim();
          const dealer_id = item[2]?.toString().trim();
          const outletType = item[3]?.toString().trim();
          const outletState = item[4]?.toString().trim();
          const region = item[5]?.toString().trim();
          const chain = item[6]?.toString().trim();
          const user = item[7]?.toString().trim();
          const outletGrade = item[8]?.toString().trim();
          const isActive = item[9]?.toString().trim();
          const sheetDataRow: SheetDataSet = {
            rc_id,
            lAccount,
            dealer_id,
            outletType,
            outletState,
            region,
            chain,
            user,
            outletGrade,
            isActive
          };
          const combination =
            rc_id +
            lAccount +
            dealer_id +
            outletType +
            outletState +
            region +
            chain +
            user +
            outletGrade +
            isActive;
          if (!encounteredCombinations.has(combination)) {
            filteredData.push(sheetDataRow);
            encounteredCombinations.add(combination);
          }
        }
      });
      if (filteredData.length > 0) {
        setNewEntries(filteredData);
      }
    });
  };

  const updateForm = (key: string, value: any) => {
    setForm((form: any) => ({
      ...form,
      [key]: value,
    }));
  };

  const sendForm = async (e: FormEvent) => {

    if (!form.isActive) {
      if (!form.dropoffReason_id) {
        alert("reason must be select")
        return
      }
    }
    setLoading(true);

    try {
      const data = {
        ...form,
        projectId,
        outletId,
      };

      if (isAdd) {
        await request("/office/outlets/new", "POST", data);
        // await addOutletDetails(data);
      } else {
        await request("/office/outlets/update", "POST", data);
        alert("The outlet has been updated.");
      }

      setLoading(false);
      handleComeBack();
    } finally {
      setLoading(false);
      handleComeBack();
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setForm({
      ...form,
      [key]: event.value,
    });
  };
  if (
    loading ||
    isLoading ||
    outletTypes.length === 0 ||
    outletStates.length === 0 ||
    treeData.length === 0 ||
    allCallTypes.length === 0 ||
    chains.length === 0
  ) {
    return <p>Loading...</p>;
  }

  const displayRender = (labels: string[]) => {
    const label = labels[labels.length - 1];
    const labelText = getLabel(label);

    return labelText ? labelText : label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: any[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  const center =
    form.latitude && form.longitude
      ? { lat: Number(form.latitude), lng: Number(form.longitude) }
      : {
        lat: -26.0356601,
        lng: 28.0472975,
      };

  const { outletPhoto } = form;

  const handleDeletePhoto = async () => {
    if (!outletId) return;
    await request("/office/outlets/delete-photo", "POST", {
      outletId,
    });
    // await deleteOutletPhoto(outletId);
    setForm({
      ...form,
      outletPhoto: "",
    });
  };

  const notifyError = () => {
    toast.error("Please choose excel file", {
      position: "bottom-center"
    });
  };


  const notifyAPIError = (message: string) => {
    toast.error(message, {
      position: "bottom-center"
    });
  };

  const notifySuccess = (message: string) => {
    toast.info(message, {
      position: "bottom-center"
    });
  };

  const handleSave = async () => {
    if (!projectId) {
      return;
    }
    if (newEntries.length === 0) {
      notifyError();
      return;
    }
    setIsLoading(true);
    const response = await request("/office/outlets/outletsMapping-import", "POST", {
      project_id: projectId,
      outlets: newEntries,
    });
    setIsLoading(false);
    if (response?.failedRcIds?.length > 0) {
      let rcIds: any = [];
      let dataErrorMessage = '';
      response?.failedRcIds && response?.failedRcIds.map((item: any, index: number) => {
        rcIds.push(item?.rc_id);
        if (dataErrorMessage === '') {
          dataErrorMessage = 'RC ID: ' + item?.rc_id + ' Error: ' + item?.error;
        } else {
          dataErrorMessage = dataErrorMessage + '\n' + 'RC ID: ' + item?.rc_id + ' Error: ' + item?.error;
        }
      })

      setDataError(dataErrorMessage);
      setRcIdsWithError(rcIds);
    } else {
      notifySuccess(response?.message);
      handleComeBack();
      setDataError('')
    }
  }

  const updateOutletStatus = async (status: boolean) => {
    if (!outletId) return;
    let end = `/office/outlets/updateOutletApprovalStatus/${outletId}`;

    let response = await request(end, "POST", {
      isApproved: status
    });

    if(response?.status === 200){
      dropOffBack()
    }
  }

  const dropOffBack = () => {
    navigate("/admin/dropoff");
  }

  const onCancel = () => {
    dropOffBack()
  }

  return (
    <>
      <Typography.Title>Outlet details:</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
      >
        <Collapse defaultActiveKey={data?.isDropOff ? ["1"] : ["2"]}>
          <Panel header="Master data" key="1">
            {outletPhoto && !data?.isDropOff && (
              <Form.Item label="Photo">
                <div>
                  <img
                    style={{
                      borderRadius: "8px",
                      border: "grey 1px solid",
                      maxWidth: "400px",
                    }}
                    src={outletPhoto}
                    alt="Outlet Photo"
                  />
                </div>
                <Button type="primary" danger onClick={handleDeletePhoto} disabled={data?.isDropOff}>
                  Delete photo
                </Button>
              </Form.Item>
            )}
            <Form.Item label="Name">
              <Input
                type="text"
                value={form.name}
                onChange={(e) => updateForm("name", e.target.value)}
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="Client Outlet Name">
              <Input
                type="text"
                value={form.clientOutletName}
                onChange={(e) => updateForm("clientOutletName", e.target.value)}
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="Street Number">
              <Input
                type="text"
                value={form.streetNumber}
                onChange={(e) => updateForm("streetNumber", e.target.value)}
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="Street Address">
              <Input
                type="text"
                value={form.streetAddress}
                onChange={(e) => updateForm("streetAddress", e.target.value)}
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="City">
              <Select
                options={allCities}
                value={form.city_id}
                onChange={(e) => updateForm("city_id", e)}
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="Master Outlet Chain">
              <Select
                loading={isLoading}
                options={chains}
                value={form.masterChain_id}
                onChange={(_value, e) => {
                  updateForm("masterChain_id", e.value);
                }}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                showSearch
                disabled={data?.isDropOff}
              />
            </Form.Item>
            <Form.Item label="Shop Number">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.shopNumber_id}
                onChange={(e) => updateForm("shopNumber_id", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Mall">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.mall}
                onChange={(e) => updateForm("mall", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Latitude">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.latitude}
                onChange={(e) => updateForm("latitude", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Longitude">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.longitude}
                onChange={(e) => updateForm("longitude", e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Telephone Number">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.telephoneNumber}
                onChange={(e) => updateForm("telephoneNumber", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Primary Email">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.primary_email}
                onChange={(e) => updateForm("primary_email", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Secondary Email">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.secondary_email}
                onChange={(e) => updateForm("secondary_email", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Alternate Number">
              <Input
                type="text"
                disabled={data?.isDropOff}
                value={form.alternativeNumber}
                onChange={(e) =>
                  updateForm("alternativeNumber", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Outlet Barcode">
              <Input
                disabled={data?.isDropOff}
                type="text"
                value={form.outletBarcode}
                onChange={(e) => updateForm("outletBarcode", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Store ID">
              <Input
                disabled={data?.isDropOff}
                type="text"
                value={form.storeCode}
                onChange={(e) => updateForm("storeCode", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Power Generating Unit">
              <Select
                disabled={data?.isDropOff}
                showSearch
                options={generatorOptions}
                value={form.generatorStatus_id}
                onChange={(e) => updateForm("generatorStatus_id", e)}
              />
            </Form.Item>
            {data?.isDropOff && <Form.Item label="Reason">
              <Input
                disabled={data?.isDropOff}
                type="text"
                value={form.dropoffReason}
                onChange={(e) => updateForm("dropoffReason_id", e.target.value)}
              />
            </Form.Item>}
            <Row>
              <Col span={14}>
                <div
                  style={{
                    width: 400,
                    height: 400,
                    marginBottom: 20,
                    marginLeft: "10%",
                  }}
                >
                  <GoogleMap
                    ref={mapRef}
                    mapContainerStyle={containerStyle}
                    center={center}
                    onUnmount={onUnmount}
                    zoom={currentZoom}
                    onZoomChanged={() => {
                      try {
                        if (!mapRef.current) return;
                        if (mapRef.current.getZoom) {
                          !data?.isDropOff && setCurrentZoom(mapRef.current.getZoom());
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    onClick={(e) => {
                      !data?.isDropOff && setForm({
                        ...form,
                        latitude: e.latLng?.lat().toString() || "0",
                        longitude: e.latLng?.lng().toString() || "0",
                      });
                    }}
                  >
                    <Marker
                      position={{
                        lat: Number(form.latitude),
                        lng: Number(form.longitude),
                      }}
                    ></Marker>
                  </GoogleMap>
                </div>
              </Col>
              <Col span={10}>
                {data?.isDropOff && <DropOffViewButton
                  onCancel={onCancel}
                  onApprove={() => updateOutletStatus(true)}
                  onReject={() => updateOutletStatus(false)}
                />}
              </Col>
            </Row>
            {/* <Form.Item label="Store Code">
          <Input
            type="text"
            value={form.storeCode}
            onChange={(e) => updateForm("storeCode", e.target.value)}
          />
        </Form.Item> */}
          </Panel>
          {
            !data?.isDropOff && <Panel header="Account mapping" key="2">
              <Divider orientation="left">Details</Divider>
              <Form.Item label="Location Account Number">
                <Input
                  type="text"
                  value={form.clientOutletCode}
                  onChange={(e) => updateForm("clientOutletCode", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Client Account ID">
                <Input
                  type="text"
                  value={form.clientOutletAccount}
                  onChange={(e) =>
                    updateForm("clientOutletAccount", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item label="Outlet Type">
                <Select
                  loading={isLoading}
                  options={outletTypes}
                  value={form.outletType_id}
                  onChange={(_value, e) => {
                    updateForm("outletType_id", e.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Outlet State"
                hasFeedback
                rules={[
                  { required: true, message: "State is required to proceed!" },
                ]}
              >
                <Select
                  loading={isLoading}
                  options={outletStates}
                  value={form.outletState_id}
                  onChange={(_value, e) => {
                    updateForm("outletState_id", e.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Region" name="hierarchy_id">
                <Cascader
                  options={treeData}
                  expandTrigger="hover"
                  displayRender={displayRender}
                  defaultValue={[getLabel(form.hierarchy_id || "")]}
                  onChange={(val) => onChange("hierarchy_id", val)}
                  showSearch={{ filter }}
                  changeOnSelect
                />
              </Form.Item>
              <Form.Item
                label="Chain"
                hasFeedback
                rules={[
                  { required: true, message: "State is required to proceed!" },
                ]}
              >
                <Select
                  loading={isLoading}
                  options={chainsProjectSpecific}
                  value={form.chain_id}
                  onChange={(_value, e) => {
                    updateForm("chain_id", e.value);
                  }}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch
                />
              </Form.Item>
              <Form.Item label="Users">
                <Select
                  showSearch
                  options={allUsers}
                  value={form.user_id}
                  allowClear
                  onChange={(e) => updateForm("user_id", e)}
                />
              </Form.Item>
              <Form.Item label="Gradings">
                <Select
                  options={allGradings}
                  value={form.outletGrade_id}
                  onChange={(e) => updateForm("outletGrade_id", e)}
                />
              </Form.Item>
              <Form.Item label="Is active" name="isActive">
                <Switch
                  defaultChecked={form.isActive}
                  onChange={(e) => updateForm("isActive", e)}
                />
              </Form.Item>
              {!form.isActive && <Form.Item label="Reason">
                <Select
                  options={reasonData}
                  value={form.dropoffReason_id}
                  onChange={(e) => updateForm("dropoffReason_id", e)}
                />
              </Form.Item>

              }
              <Divider orientation="left">Call Frequencies</Divider>
              <Form.Item label="Call Types">
                <Select
                  mode="multiple"
                  showSearch
                  options={allCallTypes}
                  value={form.callType_id}
                  onChange={(e) => updateForm("callType_id", e)}
                />
              </Form.Item>
              {/* <Form.Item label="Details"> */}
              <List
                size="small"
                bordered
                dataSource={allCallTypes.filter((item) =>
                  form.callType_id.includes(item.value)
                )}
                style={{
                  maxWidth: "400px",
                  margin: "0 auto",
                  marginBottom: "20px",
                }}
                renderItem={(item) => (
                  <List.Item>
                    <InputNumber
                      addonBefore={item.label}
                      // addonAfter="$"
                      defaultValue={0}
                      min={0}
                      value={form?.callFrequencies[item.value] || 0}
                      onChange={(e) => {
                        updateForm("callFrequencies", {
                          ...form.callFrequencies,
                          [item.value]: e,
                        });
                      }}
                    />
                  </List.Item>
                )}
              />
              <Divider orientation="left">KAM</Divider>
              <Form.Item label="Name">
                <Input
                  type="text"
                  value={form.KAMname}
                  onChange={(e) => updateForm("KAMname", e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Surname">
                <Input
                  type="text"
                  value={form.KAMsurname}
                  onChange={(e) => updateForm("KAMsurname", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  type="email"
                  value={form.KAMemail}
                  onChange={(e) => updateForm("KAMemail", e.target.value)}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  {form.isVerified ? `Update` : `Verify`}
                </Button>
              </Form.Item>
            </Panel>
          }
        </Collapse>
      </Form>
    </>
  );
};

export default UpdateOutlet;
