import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, InputRef, Space, Table } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { useContext, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { AppContext } from "../../../../App";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import PDFLetter from "./PDFLetter";
import { PDFDownloadLink } from "@react-pdf/renderer";

dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";
export interface DataType {
  user_name: string;
  count: number;
  current_latitude: string;
  current_longitude: string;
  current_location: string;
  mock_latitude: string;
  mock_longitude: string;
  mock_location: string;
  activity: string;
  date_time_of_occurrence: Date;
  firstname: string;
  lastname: string;
  region: string;
  project_name: string;
  user_role: string;
  outlet_retail_cloud_id: string;
  outlet_name: string;
  outlet_latitude: string;
  outlet_longitude: string;
  outlet_location: string;
}

type DataIndex = keyof DataType;

export interface APIProps {
  useName?: string;
  date?: string;
  page?: number;
  limit?: number;
}


const GPSSpoofingList = () => {
  const { projectId } = useContext(AppContext);
  const searchInput = useRef<InputRef>(null);

  const { request } = useApiPost();

  const [isLoading, setIsLoading] = useState(false);
  const [tempDate, setTempDate] = useState<any>(undefined);
  const [gpsListData, setGpsListData] = useState<DataType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [totalCount, setTotalCount] = useState(1)
  const [searchData, setSearchData] = useState({
    date_time_of_occurrence: "",
    user_name: "",
    page: 1,
    limit: 10,
  });

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "created_at",
      ...getColumnSearchProps("user_name", "User Name"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Region",
      dataIndex: "region",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Project",
      dataIndex: "project_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "User Role",
      dataIndex: "user_role",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Outlet Retail Cloud ID",
      dataIndex: "outlet_retail_cloud_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Outlet Name",
      dataIndex: "outlet_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Current Device GPS Location",
      dataIndex: "current_location",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Mock Location",
      dataIndex: "mock_location",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Outlet Location",
      dataIndex: "outlet_location",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Date and Time",
      dataIndex: "date_time_of_occurrence",
      ...getColumnSearchProps("date_time_of_occurrence", "Date (dd/mm/yyyy)"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Mock Location Counter",
      dataIndex: "count",
      render: (text) => <div>{text}</div>,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" key={"record"}>
          <PDFDownloadLink document={<PDFLetter selectedRecord={record} />} fileName={record?.user_name ? `${record?.user_name.replace(" ", "_")}_${new Date().getTime()}.pdf` : `${new Date().getTime()}.pdf`}>
            <Button type="primary">
              Generate Letter
            </Button>
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];
  

  useEffect(() => {
    const gpsListGet = async ({
      useName = "",
      date = "",
      page = 1,
      limit = 10,
    }: APIProps) => {
      setIsLoading(true);
      try {
        const payload = {
          projectId: projectId,
          userName: useName,
          date: date,
          page: page,
          limit: limit,
        };

        const res = await request("/office/fake-gps", "POST", payload);

        if (res?.status === 200) {
          let tempData = res?.data.map((item: DataType) => {
            return {
              ...item,
              current_location: !!item.current_latitude? `${item.current_latitude}, ${item.current_longitude}`: '',
              mock_location: !!item.mock_latitude? `${item.mock_latitude}, ${item.mock_longitude}`: '',
              outlet_location: !!item.outlet_latitude?`${item.outlet_latitude}, ${item.outlet_longitude}`: '',
               date_time_of_occurrence:moment.utc(item.date_time_of_occurrence).tz('Africa/Johannesburg').format(`${dateFormat}, hh:mm:ss A`),

            };
          });
          setTotalCount(res.total_count)
          setGpsListData([...tempData]);
        } else {
          toast.error(res?.message, {
            position: "bottom-center",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    gpsListGet({
      useName: searchData?.user_name,
      date: searchData?.date_time_of_occurrence
        ? moment(searchData?.date_time_of_occurrence, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )
        : "",
      page: searchData.page,
      limit: searchData.limit,
    });
  }, [projectId, searchData]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    let tempText = selectedKeys[0] ? selectedKeys[0] : "";
    setSearchText(tempText);
    setSearchedColumn(dataIndex);
    confirm();
    setSearchData((prev) => ({ ...prev, [dataIndex]: tempText }));
  };

  const handleReset = (clearFilters: () => void, searchType?: string) => {
    clearFilters();
    setSearchText("");
    if (searchType === "date_time_of_occurrence") {
      setTempDate(undefined);
      return;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex === "date_time_of_occurrence" ? (
          <DatePicker
            placeholder={placeholder}
            format={dateFormat}
            value={tempDate}
            defaultValue={selectedKeys[0]}
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ marginBottom: 8, display: "block" }}
            onChange={(date, dateString) => {
              setSelectedKeys(dateString ? [dateString] : []);
              setTempDate(date);
            }}
            showToday={false}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${placeholder}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
     
      return record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleUpdate = async (outletTypeId: string) => {};

  return (
    <Table
      key={projectId}
      columns={columns({
        onUpdate: handleUpdate,
      })}
      size="large"
      dataSource={gpsListData}
      rowKey={projectId}
      loading={isLoading}
      pagination={{
        total: totalCount,
        onChange(page, pageSize) {
          setSearchData(prev=>({ ...prev, page: page, limit: pageSize }));
        },
      }}
       scroll={{ x:  'max-content' }}
    />
  );
};

export default GPSSpoofingList;
