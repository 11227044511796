import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Select, Switch } from "antd";
import { EMPTY_USER } from "../../../../utils/user";
import { AppContext } from "../../../../App";
import { DataType, IOption, Delegate } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";

const DelegateForm = () => {
  const { delegateId } = useParams<{ delegateId: string }>();
  const { state } = useLocation();
  const { projectId } = useContext(AppContext);
  const [form] = Form.useForm();
  const { request, setError } = useApiPost();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [delegateRolesOptions, setDelegateRolesOptions] = useState<IOption[]>(
    []
  );
  const [genderOptions, setGenderOptions] = useState<IOption[]>([]);
  const [ethnicityOptions, setEthnicityOptions] = useState<IOption[]>([]);
  const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
  const [outletsOptions, setOutletsOptions] = useState<IOption[]>([]);
  const [delegateNetworkOptions, setdelegateNetworkOptions] = useState<
    IOption[]
  >([]);
  const [instoreProgramOptions, setInstoreProgramOptions] = useState<
    IOption[]
  >([]);
  const [delegateInstoreProgramStatus, setDelegateInstoreProgramStatus] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const headerText = delegateId ? "Edit Delegate" : "Add Delegate";

  const [formOptionValues, setFormOptionValues] = useState({
    hierarchy_id: null,
    network_id: null,
    role_id: null,
    customer_id: null,
    instore_pgm_offered: false
  });

  const [initValues, setInitValues] = useState<Delegate>(EMPTY_USER);
  const [disableInstore, setDisableInstore] = useState<boolean>(false);

  useEffect(() => {
    setInstoreProgramOptions([{ value: "Disable", text: "Disable", label: "Disable" }, { value: "Enable", text: "Enable", label: "Enable" }])
  }, []);

  useEffect(() => {
    setFormOptionValues({
      ...formOptionValues,
      ["instore_pgm_offered"]: disableInstore ? false : delegateInstoreProgramStatus
    });
  }, [disableInstore]);

  const fetchDelegateData = async () => {
    setIsLoading(true);
    if (!delegateId) {
      return;
    }

    const delegateData = await request(
      `/office/delegates/${delegateId}`,
      "POST",
      {
        projectId: state?.delegateProjectId,
      }
    );

    if (!delegateData) {
      navigate(`/admin`);
      return;
    }
    const { hierarchy_id, network_id, role_id, customer_id, language_id, instore_pgm_offered } =
      delegateData;
    setDelegateInstoreProgramStatus(instore_pgm_offered)
    if (role_id == "35024309-7ca8-4cd5-9c27-7b401a63bef3") {
      setDisableInstore(true)
    }
    setFormOptionValues({
      hierarchy_id,
      network_id,
      role_id,
      customer_id,
      instore_pgm_offered: instore_pgm_offered
    });

    setInitValues({
      ...delegateData,
      updatedAt: delegateData.updated_at
        ? new Date(delegateData.updated_at).toLocaleDateString()
        : "",
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDelegateData();
  }, [delegateId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, delegateId]);

  const handleComeBack = () => navigate("/admin/delegates");

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const {
        networks,
        roles,
        ethnicityOptions,
        genderOptions,
        languages,
        outlets,
      } = await request("/office/delegates/options/creation/delegate", "POST", {
        project_id: projectId,
      });
      setGenderOptions(genderOptions);
      setLanguageOptions(languages);
      setEthnicityOptions(ethnicityOptions);
      setDelegateRolesOptions(roles);
      setdelegateNetworkOptions(networks);
      setOutletsOptions(outlets);
      setIsLoading(false);
    };
    fetchAllData();
  }, [projectId]);

  const handleInsert = async (values: any) => {
    try {
      const response = await request("/office/delegates/insert", "POST", {
        ...values,
      });

      setIsLoading(false);
      if (response?.status === 200) {
        handleComeBack();
        toast.info(response?.message, {
          position: "top-right",
        });
      } else {
        if (response?.status === 400) {
          let error = response?.message?.error
            ? response?.message?.error
            : response?.message;
          toast.error(error, {
            position: "top-right",
          });
        } else {
          alert("something went wrong!!");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const blockDelegateUpdte = (values: Delegate) => {
    if (!initValues.isActive && !values.isActive) {
      toast.error("Delegate details can't be saved, as delegate is marked Inactive!!", {
        position: "bottom-right",
      });
      setIsLoading(false);
     return true;
    }
  }

  const handleUpdate = async (values: any) => {
    if(blockDelegateUpdte(values)){
      return;
    }
    values["send_email"] = (!delegateInstoreProgramStatus && values.instore_pgm_offered) ? true : false
    try {
      const response = await request("/office/delegates/update", "POST", {
        ...values,
        delegateId,
      });
      setIsLoading(false);
      if (response?.status === 200) {
        handleComeBack();
        toast.info(response?.message, {
          position: "top-right",
        });
      } else {
        if (response?.status === 400) {
          let error = response?.message?.error
            ? response?.message?.error
            : response?.message;
          toast.error(error, {
            position: "top-right",
          });
        } else {
          alert("something went wrong!!");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const submitData = {
      ...values,
      ...formOptionValues,
      project_id: projectId,
    };

    if (!submitData?.email) {
      return;
    }

    if (delegateId) {
      handleUpdate({
        ...submitData,
        delegateId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
    if (key === "role_id" && event.label === "Field Based Staff") return setDisableInstore(true)
    setDisableInstore(false)

  };

  const handleIstoreProgramChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value == "Enable" ? true : false,
    });
  };

  if (isLoading || genderOptions.length === 0) {
    return <p>Loading...</p>;
  }

  const displayRender = (labels: string[]) => {
    const label = labels[labels.length - 1];
    const labelText = getLabel(label);

    return labelText ? labelText : label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Form.Item label="Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Surname" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
              required: true,
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Phone Number" name="mobileNumber">
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          hasFeedback
          name="role_id"
          rules={[{ required: true, message: "Role is required to proceed!" }]}
          required
        >
          <Select
            loading={isLoading}
            options={delegateRolesOptions}
            defaultValue={formOptionValues.role_id}
            onChange={(_value, e) => {
              handleOptionChange("role_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="ID Number"
          name="idNumber"
          rules={[
            {
              required: true,
              message: "ID Number is mandatory",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Gender" name="gender">
          <Select loading={isLoading} options={genderOptions} />
        </Form.Item>
        <Form.Item label="Ethnicity" name="ethnicity_id">
          <Select loading={isLoading} options={ethnicityOptions} />
        </Form.Item>
        <Form.Item label="Language" name="language_id">
          <Select loading={isLoading} options={languageOptions} />
        </Form.Item>
        <Form.Item label="Last Change Date" name="updatedAt">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Network">
          <Select
            loading={isLoading}
            options={delegateNetworkOptions}
            defaultValue={formOptionValues.network_id}
            onChange={(_value, e) => {
              handleOptionChange("network_id", e);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Outlet"
          hasFeedback
          name="customer_id"
          required
          rules={[
            { required: true, message: "Outlet is required to proceed!" },
          ]}
        >
          <Select
            loading={isLoading}
            options={outletsOptions}
            defaultValue={formOptionValues.customer_id}
            onChange={(_value, e) => {
              handleOptionChange("customer_id", e);
            }}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            showSearch={true}
          />
        </Form.Item>

        {delegateId && <Form.Item label="Instore Program">
          <Select
            loading={isLoading}
            options={instoreProgramOptions}
            value={formOptionValues.instore_pgm_offered ? "Enable" : "Disable"}
            onChange={(_value, e) => {
              handleIstoreProgramChange("instore_pgm_offered", e);
            }}
            disabled={disableInstore}
          />
        </Form.Item>}

        <Form.Item
          label="Is Disabled"
          valuePropName="isDisabled"
          name="isDisabled"
        >
          <Switch defaultChecked={initValues.isDisabled} />
        </Form.Item>
        <Form.Item
          label="Is C3D User?"
          valuePropName="isC3DUser"
          name="isC3DUser"
        >
          <Switch defaultChecked={initValues.isC3DUser} />
        </Form.Item>
        <Form.Item label="Is M2 User?" valuePropName="isM2User" name="isM2User">
          <Switch defaultChecked={initValues.isM2User} />
        </Form.Item>
        <Form.Item
          label="Is Axonify User?"
          valuePropName="isAxonifyUser"
          name="isAxonifyUser"
        >
          <Switch defaultChecked={initValues.isAxonifyUser} />
        </Form.Item>
        <Form.Item label="Is active?" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={initValues.isActive} />
        </Form.Item>
        {delegateId && <Form.Item label="Is Instore Program?" >
          <Switch defaultChecked={initValues.instore_pgm_registered} disabled />
        </Form.Item>}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DelegateForm;
