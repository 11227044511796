import { Page, Text, View, Document, StyleSheet, Image, Link} from '@react-pdf/renderer';
import moment from 'moment-timezone';

const PDFLetter = (props:any) => {
 return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image src={require("../../../../assets/images/publicisLogo.png")} style={{ width: 90, height: 90, alignSelf: 'flex-end' }} />
          <Text style={styles.description}>{moment(props?.selectedRecord?.date_time_of_occurrence, "DD/MM/YYYY").format("DD/MM/YYYY")}</Text>
          <Text style={{ ...styles.description, marginTop: 10 }}>{props?.selectedRecord?.user_name}</Text>
          <Text style={{ ...styles.description, marginTop: 3 }}>{props?.selectedRecord?.user_role}</Text>
          <Text style={{ fontSize: 14, marginTop: 3 }}>{props?.selectedRecord?.region}</Text>
          <Text style={{ fontSize: 14, marginTop: 3 }}>{props?.selectedRecord?.project_name}</Text>
          <View style={styles.subTitle}>
            <Text style={{ fontSize: 14 }}>{'Dear '}</Text>
            <Text style={styles.description}>{props?.selectedRecord?.user_name ? props?.selectedRecord?.user_name.trim() : ""},</Text>
          </View>
          <Text style={{ ...styles.description, marginTop: 15 }} >
            Subject: Notification of Geolocation Fraud Detection
          </Text>
          <Text style={{ fontSize: 14, marginTop: 25 }}>
            We are writing to inform you that we have detected unusual activity associated with the GPS data linked to your Retail Cloud account. Specifically, it appears that there have been instances of your location being falsified using tools or software designed to manipulate GPS signals.
            <Text style={{ fontSize: 14, marginTop: 25 }}> 
            {'Your Mock location was detected at ' + props?.selectedRecord?.date_time_of_occurrence + ' ' + props?.selectedRecord?.mock_location + '.'}
          </Text>
          {props?.selectedRecord?.current_location ? <Text style={{ fontSize: 14, marginTop: 25 }}>
            {' We find that your Current location was at ' + props?.selectedRecord?.current_location + '.'}
          </Text> : null}
          {props?.selectedRecord?.outlet_retail_cloud_id ?<Text style={{ fontSize: 14, marginTop: 25 }}>
            {' Outlet Checked in was at ' + props?.selectedRecord?.outlet_name + ', ' + props?.selectedRecord?.outlet_location + ', ' + props?.selectedRecord?.outlet_retail_cloud_id + '.'}
          </Text>: null}
          <Text style={{ fontSize: 14, marginTop: 25 }}>
            {' We find this ' + props?.selectedRecord?.activity + ' was performed and the mock location was used ' + props?.selectedRecord?.count}
          </Text>
          <Text style={{ fontSize: 14, marginTop: 25 }}>
            {props?.selectedRecord?.count > 1 ? ' times.' : ' time.'}
          </Text>
          </Text>
          
          <Text style={styles.common}>{'We urge you to cease using fake GPS data immediately and ensure that all the information provided by our system is accurate and cannot be manipulated in any way. This evidence conclusively proves that you have been intentionally altering your' + " device's " + 'location.'}</Text>
          <Text style={styles.common}>{'Such actions are a violation of our ' + "company's " + 'policies.'}</Text>
          <Text style={styles.common}>
            {'The validity of the software is approved and vetted by the The World Wide Web Consortium W3C.'}
          </Text>
          <Text style={styles.common}>
            The following libraries are used to identify Fake GPS activities.
          </Text>
          <Link style={styles.link} src="https://www.npmjs.com/package/@react-native-community/geolocation">
            @react-native-community/geolocation - npm
          </Link>
          <Text style={styles.common}>
            The W3C body is also recommending to use the library. Please refer below.
          </Text>
          <Link style={styles.link} src="https://www.w3.org/2008/Talks/0904-fit2008/geoloc-ms/slides.html">W3C Geolocation API</Link>
          <Text style={{ fontSize: 14, marginTop: 25 }}>
            Also PFB the documentation by W3C body itself.
          </Text>
          <Link style={styles.link} src="https://w3c.github.io/geolocation/">Geolocation</Link>
          <Text style={{ fontSize: 14, marginTop: 25 }}>
            {'Thank you for your immediate attention to this matter.'}
          </Text>
          <Text style={{ textAlign: 'center', fontSize: 12, marginTop: 50, fontFamily:"Helvetica-Oblique" }}>{'*This is system generated letter, no signature Required.'}</Text>
 
        </View>
 
      </Page>
    </Document>
 )
}

// create style for pdf generate
const styles = StyleSheet.create({
    page: {
      flex: 1,
      backgroundColor: '#fff'
    },
    section: {
      margin: 10,
      padding: 10,
    },
    subTitle: {
      flexDirection: 'row',
      marginTop: 25,
    },
    description: {
      fontSize: 14,
      fontFamily: 'Helvetica-Bold'
    },
    common: {
      fontSize: 14,
      marginTop: 15
    },
    link: {
      fontSize: 14,
      color: "#467886"
    }
  });

export default PDFLetter;