import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";

import TableListPlaceholder from "../../../../common/TableListPlaceholder/TableListPlaceholder";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";
import { ModuleList } from "./module-type";
const ModuleHome = () => {
  const { projectId } = useContext(AppContext);
  const { request } = useApiPost();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [moduleList, setModuleList] = useState<ModuleList[]>([]);
  
  useEffect(() => {
    getModule({isActive:true});
  }, []);

  const handleTo = () => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/module/new`);
  };

  const handleUpdate = async (itmId:string, item:any) => {
    const {id,is_active} = item;
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/module/edit/${id}`,{
      state:{
        isActive:is_active,
      }
    });
  };

  const handleToggleActive = (e:boolean) => getModule({isActive:e});

  const getModule = async ({
    isActive = true
  }) => {
    setIsLoading(true);
    try {
      const res = await request(`/office/hogwarts/module/get`, "POST", {
        is_active: isActive,
        project_id: projectId,
      });

      if (res?.status === 200) {
        let tempList = res.data.map((i: any) => ({
          id: i.module_id,
          is_active: i.is_active,
          category_id: i.category_id,
          category_is_active:i.category_is_active,
          "Module Name": i.module_name,
          Category: i.category_name,
        }));

        setModuleList(tempList);
      } else {
        toast.error(res?.message, {
          position: "bottom-center",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableListPlaceholder
        handleToggleActive={handleToggleActive}
        isLoading={isLoading}
        ListData={moduleList}
        handleUpdate={handleUpdate}
        handleAdd={() => handleTo()}
        projectId={projectId}
        visibleColumns={{
          Category: true,
          "Module Name": true,
        }}
        visibleSearch={{
          Category: true,
          "Module Name": true,
        }}
      />
    </>
  );
};

export default ModuleHome;
