import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddEditForm from "../../../../common/FormPlaceHolder/AddEditForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";

const ModuleForm = () => {
  const { moduleId } = useParams<{ moduleId: string }>();

  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { projectId } = useContext(AppContext);
  const { request } = useApiPost();
  const moduleData = useRef({
    module_id: "",
    is_active: false,
    category_id: "",
    module_name: "",
    category_name: "",
    category_is_active: "",
  });

  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState<any>({
    category: "",
    module: "",
    isActive: true,
  });

  useEffect(() => {
    if (!!projectId) {
      getCategory();
    }

    return () => {
      moduleData.current = {
        module_id: "",
        is_active: false,
        category_id: "",
        module_name: "",
        category_name: "",
        category_is_active: "",
      };
    };
  }, []);

  const formItems = [
    {
      label: "Category",
      value: form?.category,
      inputType: "select",
      option: category,
    },
    {
      label: "Module",
      value: form?.module,
      inputType: "text",
    },
  ];

  const goBack = () => {
    navigate("/admin/module");
  };

  const updateForm = (key: string, value: string) => {
    setForm((form: any) => ({
      ...form,
      [key]: value,
    }));
  };

  const commonApiCall = async ({
    payload = {},
    method = "POST",
    url = "",
  }: any) => {
    setIsLoading(true);
    try {
      const res = await request(url, method, payload);

      if (res?.status === 200) {
        return res;
      } else {
        toast.error(res?.message, {
          position: "bottom-center",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const getCategory = async () => {
    try {
      let payload = {};
      if (!state?.isActive && !!moduleId) {
        payload = {
          project_id: projectId,
        };
      } else {
        payload = {
          project_id: projectId,
          is_active: true,
        };
      }
      const result = await commonApiCall({
        payload,
        url: "/office/hogwarts/category/getCategory",
      });
      if (result) {
        // setMainCategory(result)
        let tempdata = result?.data
          ?.map((item: any) => ({
            label: item.category_name,
            value: item.category_id,
            isCategActive: item.is_active,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        setCategory(tempdata);
      }
    } catch (error) {
      console.log("getCategory", error);
    } finally {
      if (!!moduleId) {
        getModule();
      }
    }
  };

  const getModule = async () => {
    try {
      const result = await commonApiCall({
        payload: {
          project_id: projectId,
          module_id: moduleId,
        },
        url: "/office/hogwarts/module/get",
      });
      if (result) {
        let tempData = result?.data?.[0];
      
        moduleData.current = tempData;
        setForm({
          category: tempData?.category_id,
          module: tempData?.module_name,
          isActive: tempData?.is_active,
          ...tempData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addModule = async () => {
    try {
      const result = await commonApiCall({
        payload: {
          category_id: form.category.trim(),
          module_name: form.module.trim(),
          is_active: form.isActive,
        },
        url: "/office/hogwarts/module/add",
      });
      if (result) {
        toast.info(result?.message, {
          position: "top-right",
        });
        goBack();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateModule = async () => {
    const { is_active, category_id } = moduleData.current;
    const {
      category: categegoryId,
      isActive,
    } = form;

    //for check category Status
    let filterData = category.filter(
      (item: any) => item.value === categegoryId
    );
    const { isCategActive } = filterData?.[0];

    if (is_active === isActive && category_id === categegoryId) {
      goBack();
      return;
    } else if (!isCategActive) {
      toast.error("Selected Category is Inactive!! Please make it active.", {
        position: "bottom-center",
      });
      return;
    }

    commonApiCall({
      payload: {
        category_id: form.category,
        module_name: form.module,
        is_active: form.isActive,
        module_id: moduleId,
      },
      url: "/office/hogwarts/module/update",
      method: "PATCH",
    }).then((response) => {
      if (response) {
        alert(response?.message);
        goBack();
      }
    });
  };

  const onSubmit = async () => {
    if (!form?.category.trim()) {
      toast.error("Please enter a valid category name!", {
        position: "bottom-center",
      });
    } else if (!form?.module.trim()) {
      toast.error("Please enter a valid module name!", {
        position: "bottom-center",
      });
    } else if (!form?.isActive && !moduleId) {
      toast.error("Module must be active!", {
        position: "bottom-center",
      });
    } else if (!projectId) {
      toast.error("Please enter a valid projectId name!", {
        position: "bottom-center",
      });
    } else {
      setIsLoading(true);
      try {
        !!moduleId ? updateModule() : addModule();
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <AddEditForm
      onSubmit={onSubmit}
      type={"Module"}
      editID={moduleId ? moduleId : ""}
      formItems={formItems}
      updateForm={updateForm}
      isActive={form?.isActive}
    />
  );
};

export default ModuleForm;
