import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";

import TableListPlaceholder from "../../../../common/TableListPlaceholder/TableListPlaceholder";
import useApiPost from "../../../../hooks/useApiPost";
const CategoryHome = () => {
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnlyActiveVisible, setIsOnlyActiveVisible] = useState(true);
  const { request, setError } = useApiPost();
  const [visibleCategory, setVisibleCategory] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getAllCategory = async () => {
      setIsLoading(true);
      if (!projectId) {
        return;
      }

      const Category = await request(
        `/office/hogwarts/category/getCategory`,
        "POST",
        {
          project_id: projectId,
          is_active: isOnlyActiveVisible,
        }
      );

      setVisibleCategory(
        Category.data.map((i: any) => {
          return { ...i, id: i.category_id ,Name:i.category_name};
        })
      );
      setIsLoading(false);
    };
    getAllCategory();
  }, [isOnlyActiveVisible, projectId]);
  const handleAdd = () => {
    const first = location.pathname.split("/")[1];

    navigate(`/${first}/category/new`);
  };

  const handleUpdate = async (id: string) => {
    const first = location.pathname.split("/")[1];
    navigate(`/${first}/category/edit/${id}`);
  };

  const handleToggleActive = () => {
    setIsOnlyActiveVisible(!isOnlyActiveVisible);
  };

  return (
    <>
      <TableListPlaceholder
        handleToggleActive={() => handleToggleActive()}
        isLoading={isLoading}
        ListData={visibleCategory}
        handleUpdate={handleUpdate}
        handleAdd={() => handleAdd()}
        projectId={projectId}
        visibleColumns={{
          Name: true,
        }}
        visibleSearch={{
          Name: true,
        }}
      />
    </>
  );
};

export default CategoryHome;
