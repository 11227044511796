import { useEffect, useState } from "react";
import mockImg from "../../assets/rc-logo-512.png";
import { Form } from "antd";
import * as S from "./login.styles";
import AndtInput from "../Andt/input/AndtInput";
import AndtButton, { ANDT_BUTTON_TYPES } from "../Andt/Button/AndtButton";
import { useNavigate } from "react-router-dom";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserLocalPersistence, sendPasswordResetEmail, } from "firebase/auth";
import useApiPost from "../../hooks/useApiPost";
import { instance } from "../../api/common-api";
import { toast } from "react-toastify";

enum BUTTON_TYPES {
  password = "PASSWORD",
  text = "TEXT",
}
const Login = (props: any) => {
  const { isLoggedIn } = props;

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/admin");
      navigate(0);
    }
  }, [isLoggedIn]);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isResetVisible, setIsResetVisible] = useState(false);
  const [error, setError] = useState("");
  const { request, noAuthRequest } = useApiPost();

  const [buttonType, setButtonType] = useState(BUTTON_TYPES.password);
  const navigate = useNavigate();

  const handleSwitchButtonType = () => {
    if (buttonType === BUTTON_TYPES.password) {
      return setButtonType(BUTTON_TYPES.text);
    } else {
      return setButtonType(BUTTON_TYPES.password);
    }
  };

  const isButtonDisabled = isResetVisible ? !userName : !userName || !password;

  const handleAction = async () => {
    if (isResetVisible) {
      setErrorMessage("");
      const data =  { email: userName };
      const authResponse = await noAuthRequest(
        "/office/forgotPass/sendForgotPasswordEmail",
        "POST",
        data
      );
      if (authResponse?.status === 200) {
        handleShowPasswordReset();
        toast.info(authResponse.message, {
          position: "bottom-center",
        });
      } else {
        toast.info(authResponse.message, {
          position: "bottom-center",
        });
      }
      
    } else {
      await handleLogin();
    }
  };

  const handleLogin = async () => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(async () => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.

        const userStatusRes = await noAuthRequest(
          "/office/auth/user-status",
          "POST",
          {'email': userName}
        );

        console.log('userStatusRes:' + JSON.stringify(userStatusRes))
        if(userStatusRes?.status === 400) {
          setErrorMessage(userStatusRes?.message)
          return
        }

        return signInWithEmailAndPassword(auth, userName, password)
          .then(async (userCredential) => {
            if (!userCredential.user.uid) {
              return;
            }
            const userAuditRes = await noAuthRequest(
              "/office/auth/user-audit",
              "POST",
              {'email': userName, 'appType': 'Web', 'reasonForFailure': '', 'loginStatus': 'success'}
            );
            return userCredential.user.getIdToken().then(async (idToken) => {
              instance.defaults.headers.common["Authorization"] = idToken;
              localStorage.setItem('AuthToken', `${idToken}`);
            });
          });
      })
      .catch(async (error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errorCode', errorCode)
        console.log('errorMessage', errorMessage)
        const userAuditRes = await noAuthRequest(
          "/office/auth/user-audit",
          "POST",
          {'email': userName, 'appType': 'Web', 'reasonForFailure': errorCode, 'loginStatus': 'failure'}
        );
        if(userAuditRes?.message &&  userAuditRes?.message !== 'Data Inserted Successfully'){
          setErrorMessage(userAuditRes?.message)
        } else if(errorCode !== null) {
          setErrorMessage('Please enter correct password.')
        }
      });

  };

  const handleShowPasswordReset = () => {
    setIsResetVisible(!isResetVisible);
  };


  return (
    <S.Wrapper>
      <S.ImageWrapper>
        <S.StyledImage src={mockImg} />
      </S.ImageWrapper>
      <S.TitleWrapper>
        <S.Title>{isResetVisible ? "Reset Password" : "Login"}</S.Title>
        {error ? <S.Error>{error}</S.Error> : <S.Error />}
      </S.TitleWrapper>
      <S.InputsWrapper>
        <AndtInput
          label={"Username"}
          type={"text"}
          placeholder={"Your address email"}
          defaultValue={userName}
          onChange={(e: any) => setUserName(e.target.value)}
        />
        {!isResetVisible && (
          <S.InputWithButtonWrapper>
            <AndtInput
              label={"Password"}
              type={buttonType}
              placeholder={"Password"}
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              onPressEnter={handleAction}
            />
            <S.ShowButton onClick={() => handleSwitchButtonType()}>
              show
            </S.ShowButton>
          </S.InputWithButtonWrapper>
        )}
        {!isResetVisible && errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <S.FormWrapper>
          <Form method="post">
            <AndtButton
              type={ANDT_BUTTON_TYPES.primary}
              isDisabled={isButtonDisabled}
              onClick={handleAction}
              text={isResetVisible ? "Reset" : "Login"}
            />
          </Form>
        </S.FormWrapper>
        <div
          style={{
            paddingTop: "10px",
          }}
        >
          <div onClick={handleShowPasswordReset}>
            {isResetVisible ? "Already have account?" : "Forgot password?"}
          </div>
        </div>
      </S.InputsWrapper>
    </S.Wrapper>
  );
};

export default Login;
