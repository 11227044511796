import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddEditForm from "../../../../common/FormPlaceHolder/AddEditForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";


const CategoryForm = () => {
    const { categoryId } = useParams<{ categoryId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { projectId } = useContext(AppContext);
    const { request } = useApiPost();
    const navigate = useNavigate();
    const [categoryActive, setCatetgoryActive] = useState<boolean>(false);
    const [form, setForm] = useState<any>({
        category: "",
        isActive: true
    });

    useEffect(() => {
        if (categoryId) {
            getCategoryDetail()
        }
    }, [])

    const getCategoryDetail = async () => {
        setIsLoading(true);
        try {
            const res = await request(`/office/hogwarts/category/getCategory`, "POST", {
                project_id: projectId,
                category_id: categoryId,
            })
            setIsLoading(false);
            if (res?.status == 200) {
                setForm({
                    category: res?.data?.category_name,
                    isActive: res?.data?.is_active
                });
                setCatetgoryActive(res?.data?.is_active)
            } else {
                toast.error(
                    "Unable to fetch category detail.",
                    {
                        position: "top-center",
                    }
                );
            }
        } finally {
            setIsLoading(false);
        }
    }

    const formItems = [{
        label: "Category",
        value: form?.category,
        inputType: "text",
    }]

    const updateForm = (key: string, value: string) => {
        setForm((form: any) => ({
            ...form,
            [key]: value,
        }));
    }

    const onSubmit = () => {
        if (!form?.category.trim()) {
            toast.error(
                "Please enter a valid category name!",
                {
                    position: "bottom-center",
                }
            );
        } else {
            if (categoryId) {
                if (categoryActive != form?.isActive) return handleCategory("update", "PATCH")
                navigate('/admin/category')
            } else {
                if (!form?.isActive) {
                    toast.error("Category must be active!", {
                        position: "bottom-center",
                    });
                } else {
                    handleCategory("insert", "POST")
                }
            }
        }
    };

    const handleCategory = async (endpoint: string, type: any) => {
        setIsLoading(true);
        try {
            const paramsObj: any = { is_active: form.isActive }
            if (categoryId) {
                paramsObj["category_id"] = categoryId
            } else {
                paramsObj["category_name"] = form.category.trim();
                paramsObj["project_id"] = projectId
            }
            const res = await request(`/office/hogwarts/category/${endpoint}`, type, paramsObj)
            setIsLoading(false);
            if (res?.status == 200) {
                if (categoryId) {
                    alert(res?.message)
                } else {
                    toast.info(
                        res?.message,
                        {
                            position: "top-right",
                        }
                    );
                }
                navigate('/admin/category')
            } else {
                toast.error(
                    res?.message,
                    {
                        position: "top-center",
                    }
                );
            }
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <AddEditForm
            onSubmit={onSubmit}
            type={"Category"}
            editID={categoryId ? categoryId : ""}
            formItems={formItems}
            updateForm={updateForm}
            isActive={form?.isActive}
        />
    );
};

export default CategoryForm;
