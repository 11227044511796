import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Collapse,
  Cascader,
  DatePicker,
  Switch,
  Alert,
  Tag,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import { DefaultOptionType } from "antd/lib/cascader";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as S from "./ClusterSpecialists.styled";
import useApiPost from "../../../../hooks/useApiPost";
import QuestionFormCommon from "../../../../common/StrategicRoute/QuestionFormCommon";
import { toast } from "react-toastify";
dayjs.extend(customParseFormat);

const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ClusterSpecialistsQuestion {
  orderNumber: number;
  text: string;
  type: string;
  maxScore: number;
  isLocal: boolean;
  options: {
    score: number;
    value: string;
    nextQuestion: number | null;
    isTriggeringRedFlag: boolean;
    emailRecipient?: string;
    redFlagCategory?: any;
    orderNumber: number;
    refQuestions: number[];
    redFlagCategoryId?: string;
    redFlagReferencedQuestions: number[] | null;
  }[];
}

export const EMPTY: any = {
  validTo: null,
  validFrom: null,
  chain_id: [],
  name: "",
  isActive: false,
  allowAdditionalRecipient: false,
  outletType_id: [],
  outletState_id: [],
  outletTypes: [],
  callType_id: [],
  hierarchy_id: [] as any,
  userRoles_id: [],
};
const StrategicAuditView = () => {
  const { request, setError } = useApiPost();
  const appContext = useContext(AppContext);
  const [treeData, setTreeData] = useState<any[]>([]);
  const { projectId } = appContext;
  const { auditId } = useParams<{ auditId: string }>();
  const { draftId } = useParams<{ draftId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUploading, setisUploading] = useState<boolean>(false);
  const [form, setForm] = useState(EMPTY);
  const [formMenu] = Form.useForm();
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [outletTypes, setOutletTypes] = useState<any[]>([]);
  const [chainsProjectSpecific, setChainsProjectSpecific] = useState<any[]>([]);
  const [outletStates, setOutletStates] = useState<any[]>([]);
  const [allCallTypes, setAllCallTypes] = useState<any[]>([]);
  const [geography, setGeography] = useState<any[]>([]);
  const [regionText, setRegionText] = useState<string[]>([]);
  const [region_id, setregion_id] = useState<string[]>([]);
  const [questions, setQuestions] = useState<ClusterSpecialistsQuestion[]>([
    {
      orderNumber: 1,
      text: "",
      type: "radio",
      maxScore: 1,
      isLocal: true,
      options: [
        {
          value: "",
          nextQuestion: 0,
          isTriggeringRedFlag: false,
          orderNumber: 1,
          score: 1,
          refQuestions: [],
          redFlagReferencedQuestions: null,
        },
      ],
    },
  ]);
  useEffect(() => {
    if (draftId) {
      fetchDataFromDraftId();
    }
    if (auditId) {
      fetchDataFromAuditId();
    }
  }, [auditId, draftId]);

  const fetchDataFromAuditId = async () => {
    const data = await request(
      `/office/strategic-audits/details/${auditId}`,
      "POST",
      {
        projectId,
      }
    );
    const { connectedOutletTypes, connectedRoles } = data;
    const { isActive, connectedRegionsText = [] } = data;
    setRegionText(connectedRegionsText);

    const { tree } = await request("/office/settings/red-flags", "POST", {
      projectId,
    });
    const { userRoles, outletTypes, callTypes, outletStates, geography } =
      await request(`/office/strategic-audits/options`, "POST", {
        projectId,
      });
    setUserRoles(userRoles);
    setOutletTypes(outletTypes);
    setChainsProjectSpecific(data.chains);
    setTreeData(tree);
    setQuestions(data.questions);

    setAllCallTypes(callTypes);
    setOutletStates(outletStates);
    setGeography(geography);

    setForm({
      validTo: dayjs(data.validTo),
      validFrom: dayjs(data.validFrom),
      chain_id: data.chain_id,
      name: data.name,
      isActive: data.isActive,
      allowAdditionalRecipient: data.allowAdditionalRecipient,
      userRoles_id: connectedRoles
        ? connectedRoles.map((u: any) => u.value)
        : [],
      outletType_id: connectedOutletTypes
        ? connectedOutletTypes.map((u: any) => u.value)
        : [],
      outletState_id: data.outletState_id
        ? data.outletState_id.map((u: any) => u.value)
        : [],
      callType_id: data.callType_id
        ? data.callType_id.map((u: any) => u.value)
        : [],
      hierarchy_id: data.region_id
        ? data.region_id.map((u: any) => u.value)
        : [],
    });
  };

  const fetchDataFromDraftId = async () => {
    const data = await request(
      `/office/strategic-audits/edit_save_draft/${draftId}`,
      "POST",
      {
        projectId,
      }
    );
    const {
      connectedOutletTypes,
      connectedRoles,
      connectedRegionsText = [],
      region_id,
    } = data;
    setregion_id(region_id);
    const { tree } = await request("/office/settings/red-flags", "POST", {
      projectId,
    });

    const { userRoles, outletTypes, callTypes, outletStates, geography } =
      await request(`/office/strategic-audits/options`, "POST", {
        projectId,
      });
    setUserRoles(userRoles);
    setOutletTypes(outletTypes);
    setChainsProjectSpecific(data.chains);
    setTreeData(tree);
    setQuestions(data.questions);
    setAllCallTypes(callTypes);
    setOutletStates(outletStates);
    setGeography(geography);
    setRegionText(connectedRegionsText);
    setForm({
      validTo: dayjs(data.validTo),
      validFrom: dayjs(data.validFrom),
      chain_id: data.chain_id,
      name: data.name,
      isActive: data.isActive,
      allowAdditionalRecipient: data.allowAdditionalRecipient,
      userRoles_id: connectedRoles
        ? connectedRoles.map((u: any) => u.value)
        : [],
      outletType_id: connectedOutletTypes
        ? connectedOutletTypes.map((u: any) => u.value)
        : [],
      outletState_id: data.outletState_id
        ? data.outletState_id.map((u: any) => u.value)
        : [],
      callType_id: data.callType_id
        ? data.callType_id.map((u: any) => u.value)
        : [],
      hierarchy_id: data.region_id
        ? data.region_id.map((u: any) => u.value)
        : [],
    });
  };
  let navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/strategic-audit");
  };
  const availableOptions = questions.map((question, index) => ({
    value: index,
    label: `Question ${index + 1}`,
  }));

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const updateForm = (key: string, value: any) => {
    setForm((form: any) => ({
      ...form,
      [key]: value,
    }));
  };

  const [formOptionValues, setFormOptionValues] = useState({
    chain_id: null,
  });

  const displayRender = (labels: string[]) => labels[labels.length - 1];
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };
  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  const generateYesNoOptions = (index: number) => (
    <div style={{ display: "flex" }}>
      <S.Column>
        <Form.Item
          label={questions[index].options[0].value}
          style={{ width: 400 }}
        >
          <Select
            options={availableOptions.filter((option) => option.value > index)}
            value={questions[index].options[0].nextQuestion}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            name={`questions[${index}].options[0].isTriggeringRedFlag`}
            checked={questions[index].options[0].isTriggeringRedFlag}
          />
          <div
            style={{
              marginTop: 20,
            }}
          >
            Score: {questions[index].options[0].score}
          </div>
          {questions[index].options[0].isTriggeringRedFlag && (
            <Form.Item label="Red Flag Category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                disabled={true}
                value={[questions[index]?.options[0]?.redFlagCategory || ""]}
                changeOnSelect
              />
            </Form.Item>
          )}
          <div
            style={{
              marginTop: 20,
            }}
          >
            Referenced Questions:
            {questions[index].options[0].redFlagReferencedQuestions}
          </div>
          {questions[index].options[0].emailRecipient && (
            <div
              style={{
                marginTop: 20,
              }}
            >
              Email recipient:
              {questions[index].options[0].emailRecipient}
            </div>
          )}
        </Form.Item>
      </S.Column>
      <S.Column>
        <Form.Item
          label={questions[index].options[1].value}
          style={{ width: 400 }}
        >
          <Select
            options={availableOptions.filter((option) => option.value > index)}
            value={questions[index].options[1].nextQuestion}
          />
        </Form.Item>
        <Form.Item label="Red Flag">
          <Input
            type="checkbox"
            name={`questions[${index}].options[1].isTriggeringRedFlag`}
            checked={questions[index].options[1].isTriggeringRedFlag}
          />
          <div
            style={{
              marginTop: 20,
            }}
          >
            Score: {questions[index].options[1].score}
          </div>
          {questions[index].options[1].isTriggeringRedFlag && (
            <Form.Item label="Red Flag Category">
              <Cascader
                showSearch={{ filter }}
                options={treeData}
                expandTrigger="hover"
                disabled={true}
                value={[questions[index]?.options[1]?.redFlagCategory || ""]}
                changeOnSelect
              />
            </Form.Item>
          )}
          <div
            style={{
              marginTop: 20,
            }}
          >
            Referenced Questions:
            {questions[index].options[1].redFlagReferencedQuestions}
          </div>
          {questions[index].options[1].emailRecipient && (
            <div
              style={{
                marginTop: 20,
              }}
            >
              Email recipient:
              {questions[index].options[1].emailRecipient}
            </div>
          )}
        </Form.Item>
      </S.Column>
    </div>
  );

  const generateText = (index: number) => (
    <Form.Item label="Next Question">
      <Select
        options={availableOptions.filter((option) => option.value > index)}
      />
    </Form.Item>
  );

  const generateRadio = (index: number) => (
    <div>
      {questions[index].options.map((option, optionIndex) => (
        <S.OptionBox key={`option_${optionIndex}`}>
          <Form.Item label="Option">
            <Input type="text" value={option.value} />
            <div
              style={{
                marginTop: 20,
              }}
            >
              Score: {option.score}
            </div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              Referenced Questions:
              {option.redFlagReferencedQuestions}
            </div>
            {option.emailRecipient && (
              <div
                style={{
                  marginTop: 20,
                }}
              >
                Email recipient:
                {option.emailRecipient}
              </div>
            )}
          </Form.Item>
        </S.OptionBox>
      ))}
    </div>
  );

  const submitForm = async () => {
    const type = formMenu.getFieldValue("buttonClicked");
    if (isUploading) {
      toast.info("Please wait while data is uploading", {
        position: "bottom-center",
      });
      return;
    }
    setisUploading(true);
    const allVals: string[] = form?.hierarchy_id?.reduce(
      (acc: string | any[], val: any) => acc.concat(val),
      []
    );
    const uniqueRegionIds = allVals?.filter((v, i, a) => a.indexOf(v) === i);

    let data = {
      ...form,
    };
    if (uniqueRegionIds) data.hierarchy_id = uniqueRegionIds;

    if (draftId && data?.hierarchy_id?.length == 0) {
      data.hierarchy_id = region_id.map((item: any) => item?.value);
    }

    if (auditId) {
      const resp = await request(
        `/office/strategic-audits/update/${auditId}`,
        "POST",
        {
          ...data,
        }
      );
      if (resp === "ok") {
        toast.info("Strategic audit updated successfully", {
          position: "bottom-center",
        });
        handleComeBack();
      } else {
        toast.info(resp, {
          position: "bottom-center",
        });
      }
    }

    if (draftId) {
      data = {
        ...data,
        draftTaskId: draftId,
        questions: questions,
      };

      if (type == "Save") {
        const resp = await request(
          `/office/strategic-audits/update_save_draft/${draftId}`,
          "POST",
          {
            projectId,
            ...data,
          }
        );
        if (resp === "ok") {
          toast.info("Draft updated successfully", {
            position: "bottom-center",
          });
          handleComeBack();
        } else {
          toast.info(resp, {
            position: "bottom-center",
          });
        }
      } else {
        const resp = await request(
          `/office/strategic-audits/insert_edited_draft`,
          "POST",
          {
            projectId,
            data: { ...data },
          }
        );
        if (resp === "ok") {
          toast.info("Draft inserted successfully", {
            position: "bottom-center",
          });
          handleComeBack();
        } else {
          toast.info(resp, {
            position: "bottom-center",
          });
        }
      }
    }
    setisUploading(false);
  };

  const deleteQuestion = async (param: any) => {
    const data = {
      questionId: param.questionId,
    };
    const resp = await request(
      `/office/strategic-audits/delete_save_draft_question`,
      "POST",
      {
        projectId,
        data,
      }
    );
    toast.info("Question deleted successfully", {
      position: "bottom-center",
    });
  };

  const handleAddNewQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      {
        orderNumber: questions.length + 1,
        text: "",
        type: "radio",
        maxScore: 1,
        isLocal: true,
        options: [
          {
            value: "",
            nextQuestion: null,
            isTriggeringRedFlag: false,
            orderNumber: 1,
            score: 1,
            refQuestions: [],
            emailRecipient: "",
            redFlagReferencedQuestions: null,
          },
        ],
      },
    ]);
  };

  if (loading) return <div>Loading...</div>;

  const isAnyQuestionWithRedFlagButNoReferencedQuestions = questions.some(
    (question) =>
      question.options.some(
        (option) =>
          option.isTriggeringRedFlag &&
          option.redFlagReferencedQuestions === null
      )
  );

  return (
    <>
      <Typography.Title>Strategic Audit</Typography.Title>
      {isAnyQuestionWithRedFlagButNoReferencedQuestions && (
        <Alert
          message="Warning"
          description="There are questions with red flag but no referenced questions. Please reach out to the administrator."
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form labelCol={{ span: 4 }} layout="horizontal" onFinish={submitForm}>
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={form.name}
            onChange={(e) => updateForm("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start"
          // name="startDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
        >
          <DatePicker
            allowClear={false}
            value={form.validFrom}
            // onChange={(e) => setStartDate(e?.toDate())}
            onChange={(e: any) => updateForm("validFrom", e)}
          />
        </Form.Item>
        <Form.Item
          label="End"
          // name="endDate"
          // getValueProps={(i: any) => ({ value: moment(i) })}
          required
          rules={[
            {
              required: true,
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            value={form.validTo}
            // onChange={(e) => setEndDate(e?.toDate())}
            onChange={(e: any) => updateForm("validTo", e)}
          />
        </Form.Item>
        <Form.Item label="User Roles">
          <Select
            loading={loading}
            options={userRoles}
            value={form.userRoles_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("userRoles_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Outlet Type">
          <Select
            loading={loading}
            options={outletTypes}
            value={form.outletType_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletType_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Chain"
          hasFeedback
          rules={[{ required: true, message: "State is required to proceed!" }]}
        >
          <Select
            loading={loading}
            options={chainsProjectSpecific}
            value={form.chain_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("chain_id", e);
            }}
          />
        </Form.Item>

        <Form.Item label="Call Types">
          <Select
            mode="multiple"
            showSearch
            options={allCallTypes}
            value={form.callType_id}
            onChange={(e) => updateForm("callType_id", e)}
          />
        </Form.Item>

        <Form.Item label="Outlet State">
          <Select
            // loading={isLoading}
            options={outletStates}
            value={form.outletState_id}
            mode="multiple"
            onChange={(e) => {
              updateForm("outletState_id", e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Region" name="hierarchy_id">
          <Cascader
            multiple
            placeholder="Please select new regions"
            options={geography}
            expandTrigger="hover"
            displayRender={displayRender}
            onChange={(val, e) => {
              setForm({
                ...form,
                hierarchy_id: val,
              });
              onChange("hierarchy_id", val);
            }}
            showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>
        {regionText && regionText.length > 0 && (
          <Form.Item label="Current Regions">
            {regionText.map((t, i) => (
              <Tag key={i}>{t}</Tag>
            ))}
          </Form.Item>
        )}

        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={form.isActive}
            checked={form.isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>
        <Form.Item label="Allow custom email" name="allowAdditionalRecipient">
          <Switch
            defaultChecked={form.allowAdditionalRecipient}
            checked={form.allowAdditionalRecipient}
            onChange={(e) => updateForm("allowAdditionalRecipient", e)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              formMenu.setFieldsValue({ buttonClicked: "Upload" });
            }}
          >
            Submit
          </Button>{" "}
          {draftId && (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                formMenu.setFieldsValue({ buttonClicked: "Save" });
              }}
            >
              Save draft
            </Button>
          )}
        </Form.Item>
        {auditId && (
          <Collapse
            bordered={false}
            style={{ padding: 0 }}
            // defaultActiveKey={["1", "0"]}
          >
            {questions.map((question, index) => (
              <Panel
                header={`(${index + 1}) ${questions[index].text}`}
                key={`${index}`}
              >
                <S.QuestionBox key={`box_${index}`}>
                  <div>
                    {question.type === "bool" && generateYesNoOptions(index)}
                    {question.type === "text" && generateText(index)}
                    {question.type === "radio" && generateRadio(index)}
                  </div>
                </S.QuestionBox>
              </Panel>
            ))}
          </Collapse>
        )}
        {draftId && (
          <QuestionFormCommon
            questions={questions}
            setQuestions={setQuestions}
            treeData={treeData}
            from={"EditDraft"}
            deleteQuestion={deleteQuestion}
            draftId={draftId}
          />
        )}
        {draftId && (
          <Button
            type="primary"
            onClick={() => {
              handleAddNewQuestion();
            }}
          >
            Add question
          </Button>
        )}
      </Form>
    </>
  );
};

export default StrategicAuditView;
