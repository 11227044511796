import { useContext, useEffect, useState } from "react";
import {
  getDashboardStocksRegional,
  getDashboardStocksChain,
  getStarterPacks,
} from "../../api/office/control-centre";
import { AppContext } from "../../App";
import BarChartComponent from "../Charts/BarChart/BarChart";
import { PieChartComponent, PaddedPieChart } from "../Charts/PieChart/PieChart";
import vodacomLogo from "../../assets/images/output-onlinejpgtools.jpg";
import { Button, Cascader, DatePicker, Select, Tooltip } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import * as S from "./Dashboard.styles";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "react-loader-spinner";
import useApiPost from "../../hooks/useApiPost";

interface LargeDoubleBoxProps {
  label1: string;
  value1: number | string;
  label2: string;
  value2: number | string;
}
interface LargeSingleBoxProps {
  label: string;
  value: number | string;
}

const LargeDoubleBox = ({
  label1,
  value1,
  label2,
  value2,
}: LargeDoubleBoxProps) => {
  return (
    <S.LargeDoubleBox>
      <S.LargeDoubleBoxColumn>
        <S.LargeBoxTitle>{label1}</S.LargeBoxTitle>
        <S.LargeBoxValue>{value1}</S.LargeBoxValue>
      </S.LargeDoubleBoxColumn>
      <S.LargeDoubleBoxColumn>
        <S.LargeBoxTitle>{label2}</S.LargeBoxTitle>
        <S.LargeBoxValue>{value2}</S.LargeBoxValue>
      </S.LargeDoubleBoxColumn>
    </S.LargeDoubleBox>
  );
};

const LargeSingleBox = ({ label, value }: LargeSingleBoxProps) => {
  return (
    <S.LargeDoubleBox>
      <S.LargeDoubleBoxColumn>
        <S.LargeBoxTitle>{label}</S.LargeBoxTitle>
        <S.LargeBoxValue>{value}</S.LargeBoxValue>
      </S.LargeDoubleBoxColumn>
    </S.LargeDoubleBox>
  );
};

export interface IDataEntry {
  id: string;
  row: "TOP" | "BOTTOM";
}

const Dashboard = () => {
  const [selectedPage, setSelectedPage] = useState<
    "main" | "stocks-regional" | "stocks-chains" | "percentages"
  >("main");
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [data, setData] = useState<any>(undefined);
  const [stocks, setStocks] = useState<any>({
    oos: [],
    lowStock: [],
    noStockDevices: [],
    lowStockDevices: [],
  });
  const [starterStocks, setStarterStocks] = useState<any>({
    data: [],
  });
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [triggerFrom, settriggerFrom] = useState<any>();
  const [chains, setChains] = useState<any[]>();
  const [malls, setMalls] = useState<any[]>();
  const [cities, setCities] = useState<any[]>();
  const [tempMall, setTempMall] = useState<any>([]);
  const [tempCity, setTempCity] = useState<any>([]);
  const [tempCityName, settempCityName] = useState<any[]>();
  const [regions, setRegions] = useState<any[]>();
  const [tempChain, setTempChain] = useState<any>([]);
  const [tempProducts, setTempProducts] = useState<any[]>([]);
  const [tempRegion, setTempRegion] = useState<any>();
  const [showfilter, setShowFilter] = useState(false);
  const [tempGeography, setTempGeography] = useState<string>("");
  const [tempYear, setTempYear] = useState<any>(undefined);
  const [tempMonth, setTempMonth] = useState<any>(undefined);
  const [tempStartDate, setTempStartDate] = useState<any>(undefined);
  const [tempEndDate, setTempEndDate] = useState<any>(undefined);

  const [selectedStocksRegionLocations, setSelectedStocksRegionLocations] =
    useState<IDataEntry[]>([]);

  const [selectedStocksRegionProducts, setSelectedStocksRegionProducts] =
    useState<IDataEntry[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { request, setError } = useApiPost();

  const getDatafromAllApi = async () => {
    let dataParams: any = {
      projectId,
    };
    if (tempGeography) {
      dataParams = { ...dataParams, selectedGeography: tempGeography };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    return await request("/office/control-centre/all", "POST", dataParams);
  };

  const getData = async () => {
    setIsLoading(true);
    const res = await getDatafromAllApi();
    if (res) {
      setTreeData(res.geography);
      setData(res);
      setChains(res.chains);
      let malls = res.malls.map((item: string) => {
        return { value: item, label: item };
      });
      let cities = res.cities.map((item: any) => {
        return { value: item?.id + "," + item?.name, label: item?.name };
      });
      if (malls.length > 0) {
        setMalls(malls);
      }
      if (cities.length > 0) {
        setCities(cities);
      }
    }
    setIsLoading(false);
  };

  const resetAllFilters = async () => {
    // resetFilters();
    // return
    const today = new Date();
    const todayMinus30 = new Date();
    todayMinus30.setDate(todayMinus30.getDate() - 30);

    setIsLoading(true);
    if (selectedPage === "main") {
      const res = await request("/office/control-centre/all", "POST", {
        projectId,
      });
      if (res) {
        setTreeData(res.geography);
        setData(res);
      }
    } else if (selectedPage === "stocks-regional") {
      const today = new Date();
      let dataParams: any = {
        projectId,
        selectedChain: "",
        startDate: todayMinus30,
        endDate: today,
      };
      if (tempMall?.length > 0) {
        dataParams = { ...dataParams, selectedMalls: tempMall };
      }
      if (tempCity?.length > 0) {
        dataParams = { ...dataParams, selectedCities: tempCity };
      }
      const res = await request(
        "/office/control-centre/dashboard-stocks-regional",
        "POST",
        dataParams
      );
      if (res) {
        setStocks(res);
        setChains(res.chains);
        let malls = res.malls.map((item: string) => {
          return { value: item, label: item };
        });
        let cities = res.cities.map((item: any) => {
          return { value: item?.id + "," + item?.name, label: item?.name };
        });
        if (malls.length > 0) {
          setMalls(malls);
        }
        if (cities.length > 0) {
          setCities(cities);
        }
        const { basicData: basicDataNew } = res;
        setData({
          ...data,
          basicData: [...basicDataNew],
        });
      }
    } else if (selectedPage === "stocks-chains") {
      const today = new Date();
      // const res = await getDashboardStocksChain(
      //   projectId,
      //   "",
      //   today.getFullYear(),
      //   today.getMonth() + 1
      // );
      let dataParams: any = {
        projectId,
        selectedRegion: "",
        chain: "",
        startDate: todayMinus30,
        endDate: today,
      };
      if (tempMall?.length > 0) {
        dataParams = { ...dataParams, selectedMalls: tempMall };
      }
      if (tempCity?.length > 0) {
        dataParams = { ...dataParams, selectedCities: tempCity };
      }
      const res = await request(
        "/office/control-centre/dashboard-stocks-chain",
        "POST",
        dataParams
      );
      if (res) {
        setStocks(res);
        setRegions(res.divisions);
        const { basicData: basicDataNew } = res;
        setData({
          ...data,
          basicData: [...basicDataNew],
        });
      }
    } else {
      const today = new Date();
      const res = await getStarterPacks(
        projectId,
        // selectedRegion,
        "",
        todayMinus30,
        today,
        // tempChain
        "",
        // today.getFullYear(),
        // today.getMonth() + 1
        []
      );
      if (res) {
        setStarterStocks(res);
        setChains(res.chains);
        let malls = res.malls.map((item: string) => {
          return { value: item, label: item };
        });
        let cities = res.cities.map((item: any) => {
          return { value: item?.id + "," + item?.name, label: item?.name };
        });
        if (malls.length > 0) {
          setMalls(malls);
        }
        if (cities.length > 0) {
          setCities(cities);
        }
        const { basicData: basicDataNew } = res;
        setData({
          ...data,
          basicData: [...basicDataNew],
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (
      triggerFrom?.length > 0 &&
      tempChain?.length == 0 &&
      !tempMonth &&
      tempMall?.length == 0 &&
      tempCity?.length == 0 &&
      !tempYear &&
      !tempRegion &&
      !tempEndDate &&
      !tempStartDate
    ) {
      if (triggerFrom == "reset") {
        resetAllFilters();
      }
      if (triggerFrom == "Regional") {
        handleApplyFilterForRegionalPage();
      }
      if (triggerFrom === "Chains") {
        handleApplyFilterForChainPage();
      }
      if (triggerFrom == "handlePreviousPage") {
        handlePreviousPage();
      }
      if (triggerFrom == "handleNextPage") {
        handleNextPage();
      }
      settriggerFrom(null);
    }
  }, [
    tempChain,
    tempMonth,
    tempMall,
    tempCity,
    tempYear,
    tempRegion,
    tempEndDate,
    tempStartDate,
    tempChain,
    triggerFrom,
  ]);

  const fetchDashboardStocksDivision = async () => {
    setIsLoading(true);
    const todayPlus30 = new Date();
    todayPlus30.setDate(todayPlus30.getDate() + 30);
    let dataParams: any = {
      projectId,
    };
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, selectedChain: tempChain };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, selectedStartDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, selectedEndDate: tempEndDate };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    const res = await request(
      `/office/control-centre/dashboard-stocks-regional`,
      "POST",
      dataParams
    );
    if (res) {
      setStocks(res);
      setChains(res.chains);
      let malls = res.malls.map((item: string) => {
        return { value: item, label: item };
      });
      let cities = res.cities.map((item: any) => {
        return { value: item?.id + "," + item?.name, label: item?.name };
      });
      if (malls.length > 0) {
        setMalls(malls);
      }
      if (cities.length > 0) {
        setCities(cities);
      }
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
      setIsLoading(false);
    }
  };

  const fetchDashboardStocksChain = async () => {
    let dataParams: any = {
      projectId,
    };
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, selectedStartDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, selectedEndDate: tempEndDate };
    }
    if (tempRegion) {
      dataParams = { ...dataParams, selectedRegion: tempRegion };
    }
    const res = await request(
      `/office/control-centre/dashboard-stocks-chain`,
      "POST",
      dataParams
    );
    if (res) {
      setStocks(res);
      setRegions(res.divisions);
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const fetchStarterPacks = async () => {
    setIsLoading(true);

    let dataParams: any = {
      projectId,
    };
    if (tempRegion) {
      dataParams = { ...dataParams, selectedRegion: tempRegion };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }
    if (tempProducts?.length > 0) {
      dataParams = { ...dataParams, products: tempProducts };
    }
    const res = await request(
      `/office/control-centre/starter-packs`,
      "POST",
      dataParams
    );
    if (res) {
      setStarterStocks(res);
      setProductOptions(res.productOptions);
      setChains(res.chains);
      let malls =
        res.malls &&
        res.malls.map((item: string) => {
          return { value: item, label: item };
        });
      let cities =
        res.cities &&
        res.cities.map((item: any) => {
          return { value: item?.id + "," + item?.name, label: item?.name };
        });
      if (malls && malls.length > 0) {
        setMalls(malls);
      }
      if (cities && cities.length > 0) {
        setCities(cities);
      }
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const handleDataFetch = async (selectedPage: string) => {
    setIsLoading(true);
    if (selectedPage === "main") {
      await getData();
    } else if (selectedPage === "stocks-regional") {
      await fetchDashboardStocksDivision();
    } else if (selectedPage === "stocks-chains") {
      await fetchDashboardStocksChain();
    } else {
      await fetchStarterPacks();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleDataFetch(selectedPage);
  }, [selectedPage]);

  const LoadingComponent = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      <div
        style={{
          color: "#00BFFF",
        }}
      >
        Loading
      </div>
    </div>
  );

  const handleApplyFilter = async () => {
    setShowFilter(false);
    setIsLoading(true);
    let dataParams: any = {
      projectId,
    };
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempGeography) {
      dataParams = { ...dataParams, selectedGeography: tempGeography };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    const res = await request("/office/control-centre/all", "POST", dataParams);
    if (res) {
      setTreeData(res.geography);
      setData(res);
    }
    setIsLoading(false);
  };

  const handleApplyStarterPacks = async () => {
    setShowFilter(false);
    setIsLoading(true);

    let dataParams: any = {
      projectId,
    };
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempRegion) {
      dataParams = { ...dataParams, selectedRegion: tempRegion };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    if (tempProducts?.length > 0) {
      dataParams = { ...dataParams, products: tempProducts };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }

    const res = await request(
      `/office/control-centre/starter-packs`,
      "POST",
      dataParams
    );
    if (res) {
      setStarterStocks(res);
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const handleApplyFilterForChainPage = async () => {
    setIsLoading(true);
    setShowFilter(false);

    let dataParams: any = {
      projectId,
    };
    if (tempRegion) {
      dataParams = { ...dataParams, selectedRegion: tempRegion };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, chain: tempChain };
    }
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    const res = await request(
      `/office/control-centre/dashboard-stocks-chain`,
      "POST",
      dataParams
    );

    if (res) {
      setStocks(res);
      setChains(res.chains);
      let malls = res.malls.map((item: string) => {
        return { value: item, label: item };
      });
      let cities = res.cities.map((item: any) => {
        return { value: item?.id + "," + item?.name, label: item?.name };
      });
      if (malls.length > 0) {
        setMalls(malls);
      }
      if (cities.length > 0) {
        setCities(cities);
      }
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const handleApplyFilterForRegionalPage = async () => {
    setShowFilter(false);
    setIsLoading(true);

    let dataParams: any = {
      projectId,
    };
    if (tempChain?.length > 0) {
      dataParams = { ...dataParams, selectedChain: tempChain };
    }
    if (tempStartDate) {
      dataParams = { ...dataParams, startDate: tempStartDate };
    }
    if (tempEndDate) {
      dataParams = { ...dataParams, endDate: tempEndDate };
    }
    if (tempMall?.length > 0) {
      dataParams = { ...dataParams, selectedMalls: tempMall };
    }
    if (tempCity?.length > 0) {
      dataParams = { ...dataParams, selectedCities: tempCity };
    }
    const res = await request(
      `/office/control-centre/dashboard-stocks-regional`,
      "POST",
      dataParams
    );
    if (res) {
      setStocks(res);
      setRegions(res.divisions);
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: any[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  const displayRender = (labels: string[]) => {
    const label = labels[labels.length - 1];
    const labelText = getLabel(label);

    return labelText ? labelText : label;
  };

  const handleOptionChange = (key: string, event: any) => {
    setTempGeography(event.value);
  };

  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const handleNextPage = () => {
    if (selectedPage === "main") {
      setSelectedPage("stocks-regional");
    } else if (selectedPage === "stocks-regional") {
      setSelectedPage("stocks-chains");
    } else if (selectedPage === "stocks-chains") {
      setSelectedPage("percentages");
    } else {
      setSelectedPage("main");
    }
  };

  const handlePreviousPage = () => {
    if (selectedPage === "main") {
      setSelectedPage("percentages");
    } else if (selectedPage === "stocks-regional") {
      setSelectedPage("main");
    } else if (selectedPage === "stocks-chains") {
      setSelectedPage("stocks-regional");
    } else {
      setSelectedPage("stocks-chains");
    }
  };

  if (!data) {
    return <LoadingComponent />;
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  let date = new Date(tempStartDate)
  const future = new Date(date.setDate(date.getDate() + 31))

  const renderMainPage = () => {
    return (
      <>
        <S.SmallColumn>
          {data.basicData.map(({ key, value, color }: any, index: number) => (
            <S.BasicBox key={index} color={color}>
              <S.BasicBoxTitle color={color}>{key}</S.BasicBoxTitle>
              <S.BasicBoxValue>{value}</S.BasicBoxValue>
            </S.BasicBox>
          ))}
          <S.LogoImage src={vodacomLogo} alt="logo" />
        </S.SmallColumn>
        <S.BasicColumn>
          <LargeDoubleBox
            label1="Total Calls Planned"
            value1={data.mainData.totalCallsPlanned}
            label2="Total Visits MTD"
            value2={data.mainData.totalVisitsMTD}
          />
          {/* <S.PaddedPieChartContainer> */}
          <PaddedPieChart
            data={data.mainData.visitsVsPlannedPercentage}
            title="Visits Completed vs Planned"
          />
          {/* </S.PaddedPieChartContainer> */}
          <LargeDoubleBox
            label1="Blitz Calls Planned"
            value1={data.mainData.blitzCallsPlanned}
            label2="Total Visits MTD"
            value2={data.mainData.blitzMTD}
          />
          <LargeDoubleBox
            label1="Standard Calls Planned"
            value1={data.mainData.standardVisitsPlanned}
            label2="Standard Visits MTD"
            value2={data.mainData.standardVisitsMTD}
          />
          <BarChartComponent
            data={data.KTFocusModules}
            color="#DD0006"
            title="TOP 5 Knowledge Transfer Focus Modules"
            type=""
          />
        </S.BasicColumn>
        <S.BasicColumn>
          <PieChartComponent
            data={data.redFlags.unresolved}
            title="Open Red Flags"
          />
          <LargeDoubleBox
            label1="Total Red Flags MTD"
            value1={data.redFlags.totalRedFlagsMTD}
            label2="Open Red Flags"
            value2={data.redFlags.unresolvedFlagsMTD}
          />
          <LargeSingleBox
            label="Percentage POS Compliance"
            value={`${data.POSCompliance.percentage}%`}
          />
          <BarChartComponent
            data={data.POSCompliance.topNonCompliance}
            color="#15A1BF"
            title="Top 10 Chains with POS Non-Compliances (Stores)"
            type=""
          />
        </S.BasicColumn>
        <S.BasicColumn>
          <S.FilterButton>
            <Button
              type="primary"
              onClick={() => setShowFilter(!showfilter)}
              // icon={<FilterOutlined />}
            >
              Filter
            </Button>
          </S.FilterButton>
          {showfilter && (
            <S.DashboardFilter>
              <S.FilterBox>
                <div>
                  <S.RegionWrapper>
                    <S.FilterLabel>Region Filter:</S.FilterLabel>
                    <Cascader
                      placeholder="Filter by Region"
                      options={treeData}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      defaultValue={
                        tempGeography ? [getLabel(tempGeography)] : []
                      }
                      onChange={(val, t) => {
                        try {
                          const label = getLabel(tempGeography);
                          if (label !== "South Africa") {
                            onChange("hierarchy_id", val);
                          }
                        } catch (error) {
                          console.log({ error });
                        }
                      }}
                      showSearch={{ filter }}
                      changeOnSelect
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Chain Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={chains || []}
                      value={tempChain}
                      onChange={(e) => setTempChain(e)}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Malls Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={malls || []}
                      value={tempMall}
                      onChange={(e) => setTempMall(e)}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Cities Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={cities || []}
                      value={tempCityName}
                      onChange={(e) => {
                        settempCityName(e);
                        let newValue = e.toString().split(",");
                        let newCityArray: any = [];
                        newValue.map((item: any, index: any) => {
                          if (index % 2 === 0) {
                            if (item?.length > 0) newCityArray.push(item);
                          }
                        });
                        setTempCity(newCityArray);
                      }}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                </div>
                <div>
                  <S.PeriodWrapper>
                    <S.FilterLabel>Start Date:</S.FilterLabel>
                    <S.DatePicker>
                      <DatePicker
                        style={{
                          width: "200px",
                        }}
                        onChange={(e) => {
                          setTempStartDate(e);
                          setTempEndDate(null);
                        }}
                        onKeyDown={(e)=>{
                          e.preventDefault()
                          return false
                        }}
                        value={tempStartDate}
                      />
                    </S.DatePicker>
                  </S.PeriodWrapper>
                  <S.PeriodWrapper>
                    <S.FilterLabel>End Date:</S.FilterLabel>
                    <S.DatePicker>
                      <DatePicker
                        style={{
                          width: "200px",
                        }}
                        onChange={(e) => {
                          setTempEndDate(e);
                        }}
                        value={tempEndDate}
                        disabled={tempStartDate ? false : true}
                        disabledDate={(d) => !d || d.isAfter(future) || d.isBefore(tempStartDate)}  
                        onKeyDown={(e)=>{
                          e.preventDefault()
                          return false
                        }}                     
                     />
                    </S.DatePicker>
                  </S.PeriodWrapper>
                </div>
                <S.FilterApplyButton>
                  <Button
                    onClick={() => setShowFilter(false)}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleApplyFilter();
                    }}
                  >
                    Apply
                  </Button>
                </S.FilterApplyButton>
              </S.FilterBox>
              {(tempChain?.length > 0 ||
                tempMall?.length > 0 ||
                tempCity?.length > 0 ||
                tempGeography ||
                tempStartDate ||
                tempEndDate) && (
                <S.CurrentFilter>
                  <Button
                    onClick={() => {
                      resetFilters("reset");
                    }}
                  >
                    Reset Filter
                  </Button>
                </S.CurrentFilter>
              )}
            </S.DashboardFilter>
          )}
          <BarChartComponent
            data={data.pricingIssues}
            color="#890D8F"
            title="Top 5 Chains pricing issues (Stores)"
            customHeight={251}
            customWidth={150}
            type="chain"
          />
          <LargeDoubleBox
            label1="Starter pack OOS"
            value1={data.mainData.outOfStockStarterPacks}
            label2="Device OOS"
            value2={data.mainData.deviceOutOfStocks}
          />
          <BarChartComponent
            data={data.devicesOutOfStock}
            color="#DD0006"
            title="Top 5 Devices OOS (Stores)"
            type="stores"
          />
        </S.BasicColumn>
      </>
    );
  };

  const resetFilters = (from: string) => {
    settriggerFrom(from);
    settempCityName([]);
    setTempChain([]);
    setTempCity([]);
    setTempMall([]);
    setTempStartDate(undefined);
    setTempEndDate(undefined);
    setTempRegion(null);
    setTempYear(null);
    setTempMonth(null);
    setTempGeography("");
    setTempStartDate(null);
    setTempEndDate(null);
    setShowFilter(false);
    onChange("hierarchy_id", null);
    onChange("year", null);
    onChange("month", null);
    onChange("chain_id", null);
    onChange("region_id", null);
    setSelectedStocksRegionLocations([]);
    setSelectedStocksRegionProducts([]);
  };

  const selectRegionalLocation = async (
    selectedLocations?: IDataEntry[],
    selectedProducts?: IDataEntry[],
    selectedRow?: "TOP" | "BOTTOM"
  ) => {
    setIsLoading(true);
    const todayPlus30 = new Date();
    todayPlus30.setDate(todayPlus30.getDate() + 30);

    const res = await getDashboardStocksRegional(
      projectId,
      tempChain,
      tempStartDate,
      tempEndDate,
      selectedLocations,
      selectedProducts,
      selectedRow
    );
    if (res) {
      setStocks(res);
      setChains(res.chains);
      let malls = res.malls.map((item: string) => {
        return { value: item, label: item };
      });
      let cities = res.cities.map((item: any) => {
        return { value: item?.id + "," + item?.name, label: item?.name };
      });
      if (malls.length > 0) {
        setMalls(malls);
      }
      if (cities.length > 0) {
        setCities(cities);
      }
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const selectChainlLocation = async (
    selectedLocations?: IDataEntry[],
    selectedProducts?: IDataEntry[],
    selectedRow?: "TOP" | "BOTTOM"
  ) => {
    setIsLoading(true);
    const todayPlus30 = new Date();
    todayPlus30.setDate(todayPlus30.getDate() + 30);

    const res = await getDashboardStocksChain(
      projectId,
      tempChain,
      tempStartDate,
      tempEndDate,
      "",
      selectedLocations,
      selectedProducts,
      selectedRow
    );
    if (res) {
      setStocks(res);
      setChains(res.chains);
      let malls = res.malls.map((item: string) => {
        return { value: item, label: item };
      });
      let cities = res.cities.map((item: any) => {
        return { value: item?.id + "," + item?.name, label: item?.name };
      });
      if (malls.length > 0) {
        setMalls(malls);
      }
      if (cities.length > 0) {
        setCities(cities);
      }
      const { basicData: basicDataNew } = res;
      setData({
        ...data,
        basicData: [...basicDataNew],
      });
    }
    setIsLoading(false);
  };

  const renderStocksPage = (title: "Regional" | "Chains") => {
    const handleBarClick = async (data: any) => {
      const value = data?.activePayload?.[0]?.payload?.id as string;
      const selectedRow = data.selectedRow;

      const isLocation = data?.type === "location";
      const isRegional = title === "Regional";
      const locationAlreadySelected = selectedStocksRegionLocations.some(
        (item) => item.id === value && item.row === selectedRow
      );
      const productAlreadySelected = selectedStocksRegionProducts.some(
        (item) => item.id === value && item.row === selectedRow
      );

      const newSelectedStocksRegionLocations = locationAlreadySelected
        ? selectedStocksRegionLocations.filter(
            (item) => !(item.id === value && item.row === selectedRow)
          )
        : [
            ...selectedStocksRegionLocations,
            {
              id: value,
              row: selectedRow,
            },
          ];

      const newSelectedStocksRegionProducts = productAlreadySelected
        ? selectedStocksRegionProducts.filter(
            (item) => !(item.id === value && item.row === selectedRow)
          )
        : [
            ...selectedStocksRegionProducts,
            {
              id: value,
              row: selectedRow,
            },
          ];

      if (isLocation) {
        //todo: figure out products
        setSelectedStocksRegionLocations(newSelectedStocksRegionLocations);
        setSelectedStocksRegionProducts(
          selectedStocksRegionProducts.filter(
            (item) => item.row !== selectedRow
          )
        );
      } else {
        //todo: figure out products
        setSelectedStocksRegionProducts(newSelectedStocksRegionProducts);
        setSelectedStocksRegionLocations(
          selectedStocksRegionLocations.filter(
            (item) => item.row !== selectedRow
          )
        );
      }

      if (isRegional) {
        if (isLocation) {
          await selectRegionalLocation(
            newSelectedStocksRegionLocations,
            undefined,
            selectedRow
          );
        } else {
          await selectRegionalLocation(
            undefined,
            newSelectedStocksRegionProducts,
            selectedRow
          );
        }
      } else {
        if (isLocation) {
          await selectChainlLocation(
            newSelectedStocksRegionLocations,
            undefined,
            selectedRow
          );
        } else {
          await selectChainlLocation(
            undefined,
            newSelectedStocksRegionProducts,
            selectedRow
          );
        }
      }
    };
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <S.SmallColumn>
          {data.basicData.map(({ key, value, color }: any, index: number) => (
            <S.BasicBox key={index} color={color}>
              <S.BasicBoxTitle color={color}>{key}</S.BasicBoxTitle>
              <S.BasicBoxValue>{value}</S.BasicBoxValue>
            </S.BasicBox>
          ))}

          <S.LogoImage src={vodacomLogo} alt="logo" />
        </S.SmallColumn>
        <div
          style={{
            display: "flex",
            maxWidth: "100%",
            flexDirection: "column",
          }}
        >
          <S.StocksGroup>
            <S.FilterButton>
              <Button
                type="primary"
                onClick={() => setShowFilter(!showfilter)}
                // icon={<FilterOutlined />}
              >
                Filter
              </Button>
            </S.FilterButton>
            {showfilter && (
              <S.DashboardFilter>
                <S.FilterBox>
                  <S.RegionWrapper>
                    {title === "Regional" ? (
                      <>
                        <S.FilterLabel>Chain Filter:</S.FilterLabel>
                        <Select
                          mode="multiple"
                          options={chains || []}
                          onChange={(e) => setTempChain(e)}
                          value={tempChain}
                          style={{ width: "200px" }}
                        />
                        <S.RegionWrapper>
                          <S.FilterLabel>Malls Filter:</S.FilterLabel>
                          <Select
                            mode="multiple"
                            options={malls || []}
                            value={tempMall}
                            onChange={(e) => setTempMall(e)}
                            style={{ width: "200px" }}
                          />
                        </S.RegionWrapper>

                        <S.RegionWrapper>
                          <S.FilterLabel>Cities Filter:</S.FilterLabel>
                          <Select
                            mode="multiple"
                            options={cities || []}
                            value={tempCityName}
                            onChange={(e) => {
                              settempCityName(e);
                              let newValue = e.toString().split(",");
                              let newCityArray: any = [];
                              newValue.map((item: any, index: any) => {
                                if (index % 2 === 0) {
                                  if (item?.length > 0) newCityArray.push(item);
                                }
                              });
                              setTempCity(newCityArray);
                            }}
                            style={{ width: "200px" }}
                          />
                        </S.RegionWrapper>
                      </>
                    ) : (
                      <>
                        <S.FilterLabel>Region Filter:</S.FilterLabel>
                        <Cascader
                          placeholder="Filter by Region"
                          options={treeData}
                          expandTrigger="hover"
                          displayRender={displayRender}
                          defaultValue={
                            tempGeography ? [getLabel(tempGeography)] : []
                          }
                          onChange={(val, t) => {
                            try {
                              const label = getLabel(tempGeography);
                              if (label !== "South Africa") {
                                onChange("hierarchy_id", val);
                              }
                            } catch (error) {
                              console.log({ error });
                            }
                          }}
                          showSearch={{ filter }}
                          changeOnSelect
                        />
                        <S.RegionWrapper>
                          <S.FilterLabel>Malls Filter:</S.FilterLabel>
                          <Select
                            mode="multiple"
                            value={tempMall}
                            options={malls || []}
                            onChange={(e) => setTempMall(e)}
                            style={{ width: "200px" }}
                          />
                        </S.RegionWrapper>

                        <S.RegionWrapper>
                          <S.FilterLabel>Cities Filter:</S.FilterLabel>
                          <Select
                            mode="multiple"
                            options={cities || []}
                            value={tempCityName}
                            onChange={(e) => {
                              settempCityName(e);
                              let newValue = e.toString().split(",");
                              let newCityArray: any = [];
                              newValue.map((item: any, index: any) => {
                                if (index % 2 === 0) {
                                  if (item?.length > 0) newCityArray.push(item);
                                }
                              });
                              setTempCity(newCityArray);
                            }}
                            style={{ width: "200px" }}
                          />
                        </S.RegionWrapper>
                      </>
                    )}
                  </S.RegionWrapper>

                  <div>
                    <S.PeriodWrapper>
                      <S.FilterLabel>Start Date:</S.FilterLabel>
                      <S.DatePicker>
                        <DatePicker
                          style={{
                            width: "200px",
                          }}
                          onChange={(e) => {
                            setTempStartDate(e);
                            setTempEndDate(null);
                          }}
                          onKeyDown={(e)=>{
                            e.preventDefault()
                            return false
                          }}
                          value={tempStartDate}
                        />
                      </S.DatePicker>
                    </S.PeriodWrapper>
                    <S.PeriodWrapper>
                      <S.FilterLabel>End Date:</S.FilterLabel>
                      <S.DatePicker>
                        <DatePicker
                          style={{
                            width: "200px",
                          }}
                          onChange={(e) => {
                            setTempEndDate(e);
                          }}
                          value={tempEndDate}
                          disabled={tempStartDate ? false : true}
                        disabledDate={(d) => !d || d.isAfter(future) || d.isBefore(tempStartDate)}  
                        onKeyDown={(e)=>{
                          e.preventDefault()
                          return false
                        }}  
                        />
                      </S.DatePicker>
                    </S.PeriodWrapper>
                  </div>
                  <S.FilterApplyButton>
                    <Button
                      onClick={() => setShowFilter(false)}
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        title === "Regional"
                          ? handleApplyFilterForRegionalPage()
                          : handleApplyFilterForChainPage();
                      }}
                    >
                      Apply
                    </Button>
                  </S.FilterApplyButton>
                </S.FilterBox>
                {(tempChain?.length > 0 ||
                  tempMall?.length > 0 ||
                  tempCity?.length > 0 ||
                  tempGeography ||
                  tempStartDate ||
                  tempEndDate) && (
                  <S.CurrentFilter>
                    {/* Current filters: */}
                    <div>
                      {/* <S.FilterItem>
                      {`${
                        title === "Regional"
                          ? `Chain: ${getChainLabel(selectedChain)}`
                          : `Region: ${getRegionLabel(selectedChain)}`
                      }: `}
                    </S.FilterItem>
                    <br /> */}
                      <Button
                        onClick={() => {
                          resetFilters(title);
                        }}
                      >
                        Reset Filter
                      </Button>
                    </div>
                  </S.CurrentFilter>
                )}
              </S.DashboardFilter>
            )}
            <S.StockRow2>
              <div>
                <BarChartComponent
                  data={stocks.oos}
                  color="#AAB438"
                  title={`${title} OOS Issues (Stores)`}
                  customWidth={720}
                  hideLegend
                  customHeight={440}
                  handleBarClick={handleBarClick}
                  type="location"
                  selectedRow="TOP"
                  activeIds={[
                    ...selectedStocksRegionLocations,
                    ...selectedStocksRegionProducts,
                  ]}
                />
              </div>
              <div>
                <BarChartComponent
                  data={stocks.noStockDevices}
                  color="#90339B"
                  title={`Devices OOS Issues (Stores)`}
                  customWidth={720}
                  hideLegend
                  customHeight={440}
                  handleBarClick={handleBarClick}
                  type="product"
                  selectedRow="TOP"
                  activeIds={[
                    ...selectedStocksRegionLocations,
                    ...selectedStocksRegionProducts,
                  ]}
                />
              </div>
            </S.StockRow2>
          </S.StocksGroup>
          <S.StocksGroup>
            <S.StockRow2>
              <div>
                <BarChartComponent
                  data={stocks.lowStock}
                  color="#AAB438"
                  title={`${title} Low Stock Issues (Stores)`}
                  customWidth={720}
                  hideLegend
                  customHeight={440}
                  handleBarClick={handleBarClick}
                  type="location"
                  selectedRow="BOTTOM"
                  activeIds={[
                    ...selectedStocksRegionLocations,
                    ...selectedStocksRegionProducts,
                  ]}
                />
              </div>
              <div>
                <BarChartComponent
                  data={stocks.lowStockDevices}
                  color="#90339B"
                  title="Devices Low Stock Issues (Stores)"
                  customWidth={720}
                  hideLegend
                  customHeight={440}
                  handleBarClick={handleBarClick}
                  type="product"
                  selectedRow="BOTTOM"
                  activeIds={[
                    ...selectedStocksRegionLocations,
                    ...selectedStocksRegionProducts,
                  ]}
                />
              </div>
            </S.StockRow2>
          </S.StocksGroup>
        </div>
      </div>
    );
  };

  const renderStartersStocksPage = () => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <S.SmallColumn>
          {data.basicData.map(({ key, value, color }: any, index: number) => (
            <S.BasicBox key={index} color={color}>
              <S.BasicBoxTitle color={color}>{key}</S.BasicBoxTitle>
              <S.BasicBoxValue>{value}</S.BasicBoxValue>
            </S.BasicBox>
          ))}

          <S.LogoImage src={vodacomLogo} alt="logo" />
        </S.SmallColumn>
        <div
          style={{
            margin: "0 auto",
          }}
        >
          <S.FilterButton>
            <Button
              type="primary"
              onClick={() => setShowFilter(!showfilter)}
              // icon={<FilterOutlined />}
            >
              Filter
            </Button>
          </S.FilterButton>
          {showfilter && (
            <S.DashboardFilter>
              <S.FilterBox>
                <div>
                  <S.RegionWrapper>
                    <S.FilterLabel>Region Filter:</S.FilterLabel>
                    <Cascader
                      placeholder="Filter by Region"
                      options={treeData}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      // value={getLabel(tempGeography[0])}
                      defaultValue={
                        tempGeography ? [getLabel(tempGeography)] : []
                      }
                      onChange={(val, t) => {
                        try {
                          const label = getLabel(tempGeography);
                          if (label !== "South Africa") {
                            onChange("hierarchy_id", val);
                          }
                        } catch (error) {
                          console.log({ error });
                        }
                      }}
                      showSearch={{ filter }}
                      changeOnSelect
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Chain Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={chains || []}
                      onChange={(e) => setTempChain(e)}
                      value={tempChain}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Products:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={productOptions || []}
                      value={tempProducts}
                      onChange={(e) => setTempProducts(e)}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                  <S.RegionWrapper>
                    <S.FilterLabel>Malls Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={malls || []}
                      value={tempMall}
                      onChange={(e) => setTempMall(e)}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>

                  <S.RegionWrapper>
                    <S.FilterLabel>Cities Filter:</S.FilterLabel>
                    <Select
                      mode="multiple"
                      options={cities || []}
                      value={tempCityName}
                      onChange={(e) => {
                        settempCityName(e);
                        let newValue = e.toString().split(",");
                        let newCityArray: any = [];
                        newValue.map((item: any, index: any) => {
                          if (index % 2 === 0) {
                            if (item?.length > 0) newCityArray.push(item);
                          }
                        });
                        setTempCity(newCityArray);
                      }}
                      style={{ width: "200px" }}
                    />
                  </S.RegionWrapper>
                </div>
                <div>
                  <S.PeriodWrapper>
                    <S.FilterLabel>Start Date:</S.FilterLabel>
                    <S.DatePicker>
                      <DatePicker
                        style={{
                          width: "200px",
                        }}
                        onChange={(e) => {
                          setTempStartDate(e);
                          setTempEndDate(null);
                        }}
                        onKeyDown={(e)=>{
                          e.preventDefault()
                          return false
                        }}
                        value={tempStartDate}
                      />
                    </S.DatePicker>
                  </S.PeriodWrapper>
                  <S.PeriodWrapper>
                    <S.FilterLabel>End Date:</S.FilterLabel>
                    <S.DatePicker>
                      <DatePicker
                        style={{
                          width: "200px",
                        }}
                        value={tempEndDate}
                        onChange={(e) => {
                          setTempEndDate(e);
                        }}
                        disabled={tempStartDate ? false : true}
                        disabledDate={(d) => !d || d.isAfter(future) || d.isBefore(tempStartDate)}  
                        onKeyDown={(e)=>{
                          e.preventDefault()
                          return false
                        }}  
                      />
                    </S.DatePicker>
                  </S.PeriodWrapper>
                </div>
                <S.FilterApplyButton>
                  <Button
                    onClick={() => setShowFilter(false)}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleApplyStarterPacks();
                    }}
                  >
                    Apply
                  </Button>
                </S.FilterApplyButton>
              </S.FilterBox>
              {(tempChain?.length > 0 ||
                tempMall?.length > 0 ||
                tempCity?.length > 0 ||
                tempGeography ||
                tempStartDate ||
                tempEndDate) && (
                <S.CurrentFilter>
                  <Button
                    onClick={() => {
                      resetFilters("reset");
                    }}
                  >
                    Reset Filter
                  </Button>
                </S.CurrentFilter>
              )}
            </S.DashboardFilter>
          )}
          {/* {showfilter && (
          <S.DashboardFilter>
            <S.FilterBox>
              <S.PeriodWrapper>
                <S.FilterLabel>Period Filter:</S.FilterLabel>
                <S.DatePicker>
                  <DatePicker
                    allowClear={false}
                    picker="month"
                    format="MMMM YYYY"
                    onChange={(e) => {
                      setTempYear(e?.year());
                      setTempMonth(e?.month());
                    }}
                  />
                </S.DatePicker>
              </S.PeriodWrapper>

              <S.FilterApplyButton>
                <Button
                  onClick={() => setShowFilter(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleApplyStarterPacks();
                  }}
                >
                  Apply
                </Button>
              </S.FilterApplyButton>
            </S.FilterBox>
          </S.DashboardFilter>
        )} */}
          <S.StockRow>
            <div
              style={{
                marginBottom: "16px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              <strong>Starter Packs Low Stock</strong>
            </div>
            <ComposedChart
              width={1200}
              height={410}
              data={
                starterStocks?.data?.sortedLowOnStock?.filter(
                  (s: any) => s["Percentage Low Stock"] > 0
                ) || []
              }
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="nameLowOnStock"
                textAnchor="end"
                interval={0}
                angle={-90}
                scale={"auto"}
                height={200}
                style={{ fontSize: "10px" }}
              />
              <YAxis tickFormatter={(value) => `${value}%`} />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Percentage Low Stock"
                barSize={20}
                fill="#413ea0"
                label={{
                  position: "top",
                  fill: "black",
                  fontStyle: "bold",
                  // angle: -90,
                }}
              />
            </ComposedChart>
          </S.StockRow>
          <S.StockRow>
            <div
              style={{
                marginBottom: "16px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              <strong>Starter Packs OOS</strong>
            </div>
            <ComposedChart
              width={1200}
              height={410}
              data={
                starterStocks?.data?.sortedOutOfStock?.filter(
                  (s: any) => s["Percentage OOS"] > 0
                ) || []
              }
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="nameOutOfStock"
                textAnchor="end"
                interval={0}
                angle={-90}
                scale={"auto"}
                height={200}
                style={{ fontSize: "10px" }}
              />
              <YAxis tickFormatter={(value) => `${value}%`} />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Percentage OOS"
                barSize={20}
                fill="#413ea0"
                label={{
                  position: "top",
                  fill: "black",
                  fontStyle: "bold",
                  // angle: -90,
                }}
              />
            </ComposedChart>
          </S.StockRow>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <S.MainWrapper>
        <S.Wrapper>
          {selectedPage === "main" && renderMainPage()}
          {selectedPage === "stocks-regional" && renderStocksPage("Regional")}
          {selectedPage === "stocks-chains" && renderStocksPage("Chains")}
          {selectedPage === "percentages" && renderStartersStocksPage()}
          {/* {selectedPage === "main" && renderStartersStocksPage()} */}
        </S.Wrapper>
      </S.MainWrapper>
      <S.Navigation>
        <S.NavigationItem>
          <Button
            onClick={() => {
              resetFilters("handlePreviousPage");
            }}
          >
            Previous
          </Button>
        </S.NavigationItem>
        <S.NavigationItem>
          <Button
            onClick={() => {
              resetFilters("handleNextPage");
              // handleNextPage()
            }}
          >
            Next
          </Button>
        </S.NavigationItem>
      </S.Navigation>
    </div>
  );
};

export default Dashboard;
