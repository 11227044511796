import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Collapse,
  Cascader,
  Modal,
  Switch,
} from "antd";
import { FormEvent } from "react";
import { DefaultOptionType } from "antd/lib/cascader";
import { AppContext } from "../../../../App";
import * as S from "./ClusterSpecialists.styled";
import useApiPost from "../../../../hooks/useApiPost";
import { useNavigate } from "react-router-dom";
import QuestionFormCommon from "../../../../common/StrategicRoute/QuestionFormCommon";
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface ClusterSpecialistsInput {
  name: string;
  allowAdditionalRecipient: boolean;
  questions: ClusterSpecialistsQuestion[];
}

interface ClusterSpecialistsQuestion {
  orderNumber: number;
  text: string;
  type: string; // "radio" | "text" | "bool";
  maxScore: number;
  isLocal: boolean;
  options: {
    score: number;
    value: string;
    nextQuestion: number | null;
    isTriggeringRedFlag: boolean;
    emailRecipient?: string;
    redFlagCategory?: any;
    orderNumber: number;
    refQuestions: number[];
    redFlagReferencedQuestions: number[] | null;
  }[];
}

const StrategicAuditForm = () => {
  const { request, setError } = useApiPost();
  const [treeData, setTreeData] = useState<any[]>([]);
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<ClusterSpecialistsQuestion[]>([]);
  const [name, setName] = useState<string>("");
  const [allowAdditionalRecipient, setAllowAdditionalRecipient] =
    useState<boolean>(false);
  let navigate = useNavigate();

  const handleComeBack = () => {
    navigate("/admin/strategic-audit");
  };
  const isAnyQuestionWithRedFlagButNoReferencedQuestions = questions.some(
    (question) =>
      question.options.some(
        (option) =>
          option.isTriggeringRedFlag &&
          (option.refQuestions.length === 0 ||
            option.redFlagCategory === "" ||
            option.redFlagCategory === null)
      )
  );

  const isRefQuestionsNotExisting = questions.some((question) =>
    question.options.some(
      (option) =>
        option.refQuestions.some(
          (refQuestion) =>
            !questions.some(
              (question) => question.orderNumber === refQuestion + 1
            )
        ) ||
        (option.nextQuestion && option.nextQuestion + 1 > questions.length)
    )
  );

  const sendForm = async (e: FormEvent) => {
    if (questions.length === 0) {
      Modal.error({
        title: "Error",
        content:
          "Please add atleast one question!",
      });
      return
    }
    const buttonClicked = form.getFieldValue("buttonClicked");
    setLoading(true);
    if (isAnyQuestionWithRedFlagButNoReferencedQuestions) {
      Modal.error({
        title: "Error",
        content:
          "There are questions with red flags but no referenced questions",
      });
      setLoading(false);
      return;
    }
    if (isRefQuestionsNotExisting) {
      Modal.error({
        title: "Error",
        content:
          "There are questions with referenced questions that do not exist",
      });
      setLoading(false);
      return;
    }
    try {
      const input: ClusterSpecialistsInput = {
        name,
        questions: questions,
        allowAdditionalRecipient,
      };
      if (buttonClicked === "save") {
        // Custom logic for the "Save" button
        await request("/office/strategic-audits/insert_save_draft", "POST", {
          projectId,
          data: input, // TODO check if data or input
          allowAdditionalRecipient,
        });
      } else if (buttonClicked === "submit") {
        // Custom logic for the "Submit" button
        await request("/office/strategic-audits/insert", "POST", {
          projectId,
          data: input, // TODO check if data or input
          allowAdditionalRecipient,
        });
      }
      setLoading(false);
    } finally {
      handleComeBack();
      setLoading(false);
    }
  };

  const fetchRedFlags = async () => {
    setIsLoading(true);
    const { tree } = await request("/office/settings/red-flags", "POST", {
      projectId,
    });
    setTreeData(tree);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRedFlags();
  }, [projectId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleAddNewQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      {
        orderNumber: questions.length + 1,
        text: "",
        type: "radio",
        maxScore: 1,
        isLocal: true,
        options: [
          {
            value: "",
            nextQuestion: null,
            isTriggeringRedFlag: false,
            orderNumber: 1,
            score: 1,
            refQuestions: [],
            emailRecipient: "",
            redFlagReferencedQuestions: null,
          },
        ],
      },
    ]);
  };

  return (
    <>
      <Typography.Title>Strategic Audit</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={sendForm}
        form={form}
      >
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Allow custom email" name="allowAdditionalRecipient">
          <Switch
            checked={allowAdditionalRecipient}
            onChange={(e) => {
              setAllowAdditionalRecipient(e);
            }}
          />
        </Form.Item>
        <QuestionFormCommon
          questions={questions}
          setQuestions={setQuestions}
          treeData={treeData}
          from={"AddDraft"}
          deleteQuestion={()=>{}}
          draftId={null}
        />
        <Button type="primary" onClick={() => handleAddNewQuestion()}>
          Add question
        </Button>
         <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.setFieldsValue({ buttonClicked: "submit" });
            }}
          >
            Submit
          </Button>{" "}
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.setFieldsValue({ buttonClicked: "save" });
            }}
          >
            Save draft
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default StrategicAuditForm;
