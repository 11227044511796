import { useContext } from "react";
import { Form, Button, Typography, Select, Input, Switch } from "antd";
import { AppContext } from "../../App";

const AddEditForm = (props: any) => {
  const { onSubmit, editID, type, formItems, updateForm, isActive } = props;
  const { projectName } = useContext(AppContext);

  const headerText = editID ? `Edit ${type}` : `Add ${type}`;

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Form.Item label="Project Name">
          <Input type="text" value={projectName} disabled />
        </Form.Item>
        {formItems.map((i: any, index: any) => {
          return (
            <Form.Item label={i.label} key={index.toString()} required>
              {i.inputType == "text" ? (
                <Input
                  type={i.inputType}
                  maxLength={255}
                  value={i.value}
                  onChange={(e) =>
                    updateForm(i.label.toLowerCase(), e.target.value)
                  }
                  disabled={editID != ""}
                />
              ) : (
                <Select
                  value={i.value}
                  mode={i.inputType}
                  options={i.option}
                  onChange={(e) => updateForm(i.label.toLowerCase(), e)}
                />
              )}
            </Form.Item>
          );
        })}
        <Form.Item label="Is active" name="isActive">
          <Switch
            defaultChecked={isActive}
            onChange={(e) => updateForm("isActive", e)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddEditForm;
