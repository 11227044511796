import { useEffect, useState, useContext } from "react";
import { Form, Input, Button, Typography, Switch, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";

const OutletTypeForm = () => {
  const { outletTypeId } = useParams<{ outletTypeId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const headerText = outletTypeId ? "Edit Outlet type" : "Add Outlet type";
  const { request, setError } = useApiPost();
  const { projectId } = useContext(AppContext);
  const [messageApi, contextHolder] = message.useMessage();
  const warn = (content: string) => {
    message.warning(content);
  };


  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (!outletTypeId) {
        setIsLoading(false);
        return;
      }

      const res = await request(`/office/outletTypes/list`, "POST", {
        outletTypeId: outletTypeId,
        project_id: projectId
      });
      setName(res.data.outletTypeName)
      setIsActive(res.data.isActive)
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [outletTypeId]);

  const handleComeBack = () => navigate("/admin/outlet-type");

  const handleInsert = async () => {
    try {
      setIsLoading(true);
      const res = await request("/office/outletTypes/add", "POST", {
        outletTypeName: name.trim(),
        isActive,
        project_id: projectId
      });
      if (res.error) {
        alert(res.message);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      let res = await request("/office/outletTypes/toggleActive", "POST", {
        outletTypeId: outletTypeId,
        isActive,
      });

     
      if(res.status == 202)
        warn(res.message)
      

      setIsLoading(false);
      handleComeBack();

    } finally {
    setIsLoading(false);
  }
};

const onFinish = async (values: any) => {
  if (!name.trim())
    return warn("Please fill required fields");
  else if (name.length > 255)
    return warn("Name should not be greater then 255 characters");

  if (outletTypeId)
    handleUpdate()
  else
    handleInsert()
};

if (isLoading) {
  return <p>Loading...</p>;
}


return (
  <>
    <Typography.Title>{headerText}</Typography.Title>
    <Form labelCol={{ span: 4 }}
      onFinish={onFinish}
    >
      <Form.Item label="Name">
        {outletTypeId ? (
          <div>{name}</div>
        ) : (<Input value={name} onChange={(e) => setName(e.target.value)} />)}

      </Form.Item>
      <Form.Item label="Is active" valuePropName="isActive" name="isActive">
        <Switch defaultChecked={isActive} onChange={(e) => setIsActive(e)} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
         {outletTypeId ?  'Save' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  </>
);
};

export default OutletTypeForm;
