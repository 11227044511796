import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Typography, List, Tag, Select } from "antd";
import { AppContext } from "../../../../App";
import { IOption } from "../types";
import { getAuth } from "firebase/auth";
import useApiPost from "../../../../hooks/useApiPost";
import * as S from "./FormalTraining.styled";

const FormalTrainingView = () => {
  const appContext = useContext(AppContext);
  const { eventId } = useParams<{ eventId: string }>();
  const auth = getAuth();
  const addedByEmail = auth.currentUser?.email || "not@defined.com";
  const { request, setError } = useApiPost();

  const { projectId } = appContext;
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");

  const [attendees, setAttendees] = useState<any[]>([]);
  const [date, setDate] = useState<any>(null);
  let navigate = useNavigate();

  const fetchDetails = async () => {
    if (!eventId) return;
    setLoading(true);
    await loadData(eventId);
    setLoading(false);
  };

  const loadData = async (eventId: string) => {
    const eventDetails = await request(
      "/office/training/get-view-formal-trainings-details",
      "POST",
      {
        event_id: eventId,
        project_id: projectId,
      }
    );
    const { name, attendees, startDate, delegates, location } = eventDetails;
    setLocation(location);
    setTitle(name);
    setDate(startDate);
    setAttendees(attendees);

    const delegatesOptions = delegates.map((delegate: any) => ({
      label: `${delegate.firstName} ${delegate.lastName} ID: ${delegate.IDNumber}`,
      value: delegate.id,
    }));

    setDelegateOptions(delegatesOptions);
  };

  useEffect(() => {
    fetchDetails();
  }, [eventId]);

  const handleComeBack = () => {
    navigate("/admin/formal-trainings");
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [delegateOptions, setDelegateOptions] = useState<IOption[]>([]);
  const [newDelegate, setNewDelegate] = useState<string[]>([]);

  const handleSendQuestionnaires = async () => {
    if (!eventId) return;
    await request("/office/training/send-questionnaires-to-users", "POST", {
      event_id: eventId,
    });
    fetchDetails();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const isAnyQuestionnaireSent = attendees.some(
    (attendee) => attendee.questionnaireLink
  );

  const retryAttempt = async (id: string) => {
    if (!eventId) return;
    setIsActionLoading(true);
    // await retryQuestionnaire(eventId, id);
    await request("/office/training/retry-questionnaire", "POST", {
      event_id: eventId,
      delegate_id: id,
    });
    loadData(eventId);
    setIsActionLoading(false);
  };

  const resentQuestionnaire = async (id: string) => {
    if (!eventId) return;
    setIsActionLoading(true);
    await request(
      "/office/training/send-questionnaire-to-single-user",
      "POST",
      {
        event_id: eventId,
        delegate_id: id,
      }
    );
    loadData(eventId);
    setIsActionLoading(false);
  };

  const markAsAbsent = async (id: string) => {
    if (!eventId) return;
    setIsActionLoading(true);
    await request("/office/training/mark-as-absent", "POST", {
      event_id: eventId,
      delegate_id: id,
    });
    loadData(eventId);
    setIsActionLoading(false);
  };

  // const isToday = new Date().toDateString() === new Date(date).toDateString();

  console.log({ attendees });
  const ShowPercentages = (item: any) => {
    const { results } = item;

    const showRetry =
      results.some((item: any) => item.percentage < 80) &&
      item.status !== "Retry";

    const showActiveUrl =
      item.status === "Signed up" ||
      item.status === "Started" ||
      item.status === "Retry";
    return (
      <S.AttemptsScores>
        <div>Scores</div>
        <div>
          {results.map((item: any, index: number) => (
            <S.AttemptRow key={index}>
              <S.AttemptNumber>{`Attempt #${index + 1}:`}</S.AttemptNumber>
              <S.ScoreWrapper score={item.percentage}>
                {item.percentage?.toFixed(2)}%
              </S.ScoreWrapper>
            </S.AttemptRow>
          ))}
          {showRetry && (
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={() => retryAttempt(item.id)}
              disabled={isActionLoading}
            >
              Retry
            </Button>
          )}
        </div>
        {showActiveUrl && (
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              // justifyContent: "space-between",
              // width: "100%",
            }}
          >
            <div>
              <Button
                type="primary"
                style={{ margin: 10 }}
                onClick={() => resentQuestionnaire(item.id)}
                disabled={isActionLoading}
              >
                Re-send URL
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                style={{ margin: 10 }}
                onClick={() =>
                  navigator.clipboard.writeText(
                    `https://backoffice-dev.retailcloud.online/questionnaire/${item.questionnaireLink}`
                  )
                }
              >
                Copy URL
              </Button>
            </div>
          </div>
        )}
      </S.AttemptsScores>
    );
  };

  const ShowSendURL = (item: any) => {
    const { questionnaireLink } = item;

    return (
      <S.AttemptsScores>
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-between",
            // width: "100%",
          }}
        >
          <div>
            <Button
              type="primary"
              style={{ margin: 10 }}
              onClick={() => resentQuestionnaire(item.id)}
              disabled={isActionLoading}
            >
              Send URL
            </Button>
          </div>
        </div>
      </S.AttemptsScores>
    );
  };

  const statusColorMap: any = {
    "Signed up": "blue",
    Submitted: "green",
    Started: "green",
    Retry: "orange",
    Absent: "red",
  };

  const confirmAddDelegate = async () => {
    if (!eventId || !newDelegate) return;
    setLoading(true);
    await request("/office/training/add-attendees", "POST", {
      delegate_ids: newDelegate,
      event_id: eventId,
      addedByEmail,
    });
    await fetchDetails();
    setNewDelegate([]);
    setLoading(false);
  };

  return (
    <>
      <Typography.Title>{`Formal Training Event: ${title}`}</Typography.Title>
      <S.Datetext>
        Location: {location ? location : "Virtual Training"}
      </S.Datetext>
      <S.Datetext>Date: {new Date(date).toLocaleString()}</S.Datetext>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <List
          size="small"
          bordered
          loading={loading}
          dataSource={attendees}
          // dataSource={allCallTypes.filter((item) =>
          //   form.callType_id.includes(item.value)
          // )}
          style={{
            margin: "0 auto",
            marginBottom: "20px",
          }}
          renderItem={(item: any) => {
            const isAbsent = item.status === "Absent";
            const isOnlySignedUp =
              item.status === "Signed up" &&
              item.results.length === 0 &&
              item.questionnaireLink === null &&
              !isAbsent;
            const showAbsent = item.questionnaireLink === null && !isAbsent;
            return (
              <List.Item key={item.email}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div></div>
                  <S.Prop>
                    <S.BoldText>Status:</S.BoldText>
                    <Tag
                      color={statusColorMap[item.status]}
                      style={{ marginLeft: 10 }}
                    >
                      {item.status}
                    </Tag>
                    {/* {!isAbsent && 

                    (  <Tag color="red" style={{ marginLeft: 10 }}>
                    {item.status}
                  </Tag>)
                    // <S.Value>{item.status}</S.Value>)
                    } */}
                  </S.Prop>
                  <S.Prop>
                    <S.BoldText>Name:</S.BoldText>
                    <S.Value>{`${item.firstName} ${item.lastName}`}</S.Value>
                  </S.Prop>
                  <S.Prop>
                    <S.BoldText>Phone Number:</S.BoldText>
                    <S.Value>{item.mobileNumber}</S.Value>
                  </S.Prop>
                  <S.Prop>
                    <S.BoldText>Email:</S.BoldText>
                    <S.Value>{item.email}</S.Value>
                  </S.Prop>
                  <S.Prop>
                    <S.BoldText>ID Number:</S.BoldText>
                    <S.Value>{item.IDNumber}</S.Value>
                  </S.Prop>
                  {showAbsent && (
                    <S.Prop>
                      <Button
                        type="dashed"
                        danger
                        style={{ margin: 10 }}
                        onClick={() => markAsAbsent(item.id)}
                        disabled={isActionLoading}
                      >
                        Mark as absent
                      </Button>
                    </S.Prop>
                  )}
                </div>
                {item.percentage !== null && !item.results && (
                  <>
                    <div>
                      <S.ScoreWrapper score={item.percentage}>
                        Score: {item.percentage?.toFixed(2)}%
                      </S.ScoreWrapper>
                    </div>
                    {item.percentage < 80 ? (
                      <Button
                        type="primary"
                        style={{ margin: 10 }}
                        onClick={() => retryAttempt(item.id)}
                        disabled={isActionLoading}
                      >
                        Retry
                      </Button>
                    ) : null}
                  </>
                )}
                {!isOnlySignedUp && ShowPercentages(item)}
                {!isAbsent &&
                  item.questionnaireLink === null &&
                  ShowSendURL(item)}
                {!isAbsent &&
                  item.questionnaireLink &&
                  item.percentage === null && (
                    <>
                      <div>
                        <Button
                          type="primary"
                          style={{ margin: 10 }}
                          onClick={() => resentQuestionnaire(item.id)}
                          disabled={isActionLoading}
                        >
                          Re-send URL
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="primary"
                          style={{ margin: 10 }}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `https://backoffice-dev.retailcloud.online/questionnaire/${item.questionnaireLink}`
                            )
                          }
                        >
                          Copy URL
                        </Button>
                      </div>
                    </>
                  )}
                {/* {item.percentage !== null && (
                <>
                  <div>
                    <Button type="primary" style={{ margin: 10 }}>
                      View answers
                    </Button>
                  </div>
                </>
              )} */}
              </List.Item>
            );
          }}
        />
        <Form.Item>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !eventId || isAnyQuestionnaireSent || attendees.length === 0
              }
              onClick={() => handleSendQuestionnaires()}
            >
              {isAnyQuestionnaireSent
                ? "Questionnaires sent"
                : "Send Questionnaires"}
            </Button>
          </div>
        </Form.Item>
        <div>
          <Form.Item label="Add Attendees">
            <Select
              options={delegateOptions}
              onChange={(e) => setNewDelegate(e)}
              showSearch
              // value={form.newDelegates_id}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              mode="multiple"
            />
            <Button
              type="primary"
              style={{
                marginTop: "10px",
              }}
              onClick={() => {
                confirmAddDelegate();
              }}
            >
              Confirm
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default FormalTrainingView;
