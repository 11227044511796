import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, Switch, InputRef, Modal } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const TableListPlaceholder = (props: any) => {
  const {
    handleToggleActive,
    isLoading,
    ListData,
    handleUpdate,
    handleAdd,
    projectId,
    visibleColumns,
    visibleSearch,
  } = props;

  // Define columns with static isActive and action columns
  const columns: ColumnsType<{ is_active: boolean }> = [
    {
      title: "Active",
      dataIndex: "is_active",
      render: (val: any) => <Switch checked={val} disabled />,
    },
  ];

  const searchInput = useRef<InputRef>(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) =>
      visibleSearch[dataIndex] ? (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ) : null,
    filterIcon: (filtered: boolean) =>
      visibleSearch[dataIndex] ? (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ) : null,
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return visibleSearch[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : null;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        visibleSearch[dataIndex] &&
          setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      visibleSearch[dataIndex] ? (
        searchedColumn === dataIndex ? (
          //@ts-ignore

          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        )
      ) : null,
  });

  // Generate dynamic columns based on the first data object
  const dynamicColumns = Object.keys(ListData[0] || {})
    .filter((key) => key !== "isActive" && key !== "action")
    .map((key) => ({
      title: key,
      dataIndex: key,
      ...getColumnSearchProps(key),
      render: (text: any) => <div>{text}</div>,
      visible: visibleColumns[key],
    }));

  const finalColumns = [
    ...columns,
    ...dynamicColumns.filter((col) => col.visible),
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => handleUpdate(record.id, record)}
          >
            Update
          </Button>
        </Space>
      ),
    },
  ]; // Only include visible columns];

  return (
    <>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
            onChange={(e:boolean) => handleToggleActive(e)}
          />
        </div>
      </div>
      <Table
        size="small"
        pagination={{
          pageSize: 20,
        }}
        loading={isLoading}
        key={ListData && ListData?.length > 0 ? ListData[0]?.id : projectId}
        columns={finalColumns}
        dataSource={ListData}
        rowKey={ListData && ListData?.length > 0 ? ListData[0]?.id : projectId}
      />
      <Button type="primary" onClick={handleAdd}>
        Add
      </Button>
    </>
  );
};

export default TableListPlaceholder;
