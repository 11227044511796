import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "antd/dist/antd.css";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Select } from "antd";
import "./index.css";
import { AppContext } from "../App";
import { Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import authCtx from "../store/auth/AuthContextProvider";
import useApiPost from "../hooks/useApiPost";

const { Header, Sider, Content } = Layout;

const AdminDashboardLayout = () => {
  const auth = getAuth();
  const { setProject, projectId, setProjectName } = useContext(AppContext);
  const { globalLogOutDispatch, authState } = useContext(authCtx);
  const { request } = useApiPost();

  const [isLoading, setIsLoading] = useState(false);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [projectOptions, setProjectOptions] = useState<any>([]);

  const { email, role, access, projectAccess } = authState;
  let navigate = useNavigate();

  const collapsibleProps = {
    className: "trigger",
    onClick: () => setCollapsed(!collapsed),
  };

  const handleChange = (e: any) => {
    setProject(e.value);
    setProjectName(e.label);
  };

  const layoutStyles = {
    display: "flex",
    margin: "auto",
    borderRadius: "16px",
    height: "100%",
  };

  useEffect(() => {
    const getAllProjects = async () => {
      setIsLoading(true);

      const options = await request("/office/projects/options");

      const availableOptions =
        role === "Admin"
          ? options
          : options.filter((option: any) =>
            projectAccess.length > 0
              ? projectAccess.includes(option.value)
              : "National Chains" === option.label
          );

      if (
        !availableOptions.some(
          (option: any) => option.label === "National Chains"
        )
      ) {
        setProject(availableOptions[0].value);
        setProjectName(availableOptions[0].label);
      }

      setProjectOptions(availableOptions);
      setIsLoading(false);
    };
    getAllProjects();
  }, [email, role, projectAccess]);

  const adminMenuItems = [
    {
      key: "knowledge-group",
      icon: <VerticalLeftOutlined />,
      label: "Knowledge",
      sequence: 3,
      children: [
        {
          key: "coaching-calendar",
          icon: <VerticalLeftOutlined />,
          label: "Coach Calendar",
          onClick: () => navigate("/admin/coaching-calendar"),
        },
        {
          key: "delegates",
          icon: <VerticalLeftOutlined />,
          label: "Delegates",
          onClick: () => navigate("/admin/delegates"),
        },
        {
          key: "diary-managemet",
          icon: <VerticalLeftOutlined />,
          label: "Manage Diary",
          onClick: () => navigate("/admin/diary-management"),
        },
        {
          key: "formal-trainings",
          icon: <VerticalLeftOutlined />,
          label: "Formal Trainings",
          onClick: () => navigate("/admin/formal-trainings"),
        },
        {
          key: "store-finder",
          icon: <VerticalLeftOutlined />,
          label: "Store Finder",
          onClick: () => navigate("/admin/store-finder"),
        },
        {
          key: "training-venues",
          icon: <VerticalLeftOutlined />,
          label: "Training Venues",
          onClick: () => navigate("/admin/training-venues"),
        },
      ],
    },
    {
      key: "instore-program",
      icon: <VerticalLeftOutlined />,
      label: "Instore Program",
      sequence: 1,
      children: [
        {
          key: "category",
          icon: <VerticalLeftOutlined />,
          label: "Category",
          sequence: 1,
          onClick: () => navigate("/admin/category"),
        },
        {
          key: "module",
          icon: <VerticalLeftOutlined />,
          label: "Module",
          sequence: 2,
          onClick: () => navigate("/admin/module"),
        },
        {
          key: "level",
          icon: <VerticalLeftOutlined />,
          label: "Level",
          sequence: 3,
          onClick: () => navigate("/admin/level"),
        },
        {
          key: "media_content",
          icon: <VerticalLeftOutlined />,
          label: "Media Content",
          sequence: 4,
          onClick: () => navigate("/admin/media_content"),
        },
        {
          key: "assessment",
          icon: <VerticalLeftOutlined />,
          label: "Assessment",
          sequence: 5,
          onClick: () => navigate("/admin/assessment"),
        },
      ],
    },
    {
      key: "execution-group",
      icon: <VerticalLeftOutlined />,
      label: "Execution",
      sequence: 2,
      children: [
        {
          key: "calls",
          icon: <VerticalLeftOutlined />,
          label: "Calls Manager",
          onClick: () => navigate("/admin/calls"),
        },
        {
          key: "reports",
          icon: <VerticalLeftOutlined />,
          label: "Reports",
          onClick: () => navigate("/admin/reports"),
        },
        {
          key: "tv-dashboard",
          icon: <VerticalLeftOutlined />,
          label: "Dashboard",
          onClick: () => navigate("/admin/tv-dashboard"),
        },
        {
          key: "maps",
          icon: <VerticalLeftOutlined />,
          label: "Maps",
          onClick: () => navigate("/admin/map"),
        },
        {
          key: "red-flags",
          icon: <VerticalLeftOutlined />,
          label: "Red Flags",
          onClick: () => navigate("/admin/red-flags"),
        },
      ],
    },
    {
      key: "master-data-group",
      icon: <VerticalLeftOutlined />,
      label: "Master Data",
      sequence: 4,
      children: [
        {
          key: "access-projects",
          icon: <VerticalLeftOutlined />,
          label: "Access Projects",
          onClick: () => navigate("/admin/project-access"),
        },
        {
          key: "access-roles",
          icon: <VerticalLeftOutlined />,
          label: "Access Roles",
          onClick: () => navigate("/admin/system-roles"),
        },
        {
          key: "access",
          icon: <VerticalLeftOutlined />,
          label: "Access",
          onClick: () => navigate("/admin/access"),
        },
        {
          key: "call-types",
          icon: <VerticalLeftOutlined />,
          label: "Call Types",
          onClick: () => navigate("/admin/call-types"),
        },
        {
          key: "cities",
          icon: <VerticalLeftOutlined />,
          label: "Cities",
          onClick: () => navigate("/admin/cities"),
        },
        {
          key: "channels",
          icon: <VerticalLeftOutlined />,
          label: "Channels",
          onClick: () => navigate("/admin/channels"),
        },
        {
          key: "chains",
          icon: <VerticalLeftOutlined />,
          label: "Chains",
          onClick: () => navigate("/admin/chains"),
        },
        {
          key: "timesheets-work-types",
          icon: <VerticalLeftOutlined />,
          label: "Timesheets WorkType",
          onClick: () => navigate("/admin/timesheets-worktypes"),
        },
        {
          key: "content-management",
          icon: <VerticalLeftOutlined />,
          label: "Content Management",
          onClick: () => navigate("/admin/content-management"),
        },
        {
          key: "newsfeed",
          icon: <VerticalLeftOutlined />,
          label: "Newsfeed",
          onClick: () => navigate("/admin/newsfeed"),
        },
        {
          key: "cycles",
          icon: <VerticalLeftOutlined />,
          label: "Cycles",
          onClick: () => navigate("/admin/cycles"),
        },
        {
          key: "delegate-transfers",
          icon: <VerticalLeftOutlined />,
          label: "Delegates Transfers",
          onClick: () => navigate("/admin/delegate-transfers"),
        },
        {
          key: "diary",
          icon: <VerticalLeftOutlined />,
          label: "Diary Imports",
          onClick: () => navigate("/admin/diary"),
        },
        {
          key: "outlet-imports",
          icon: <VerticalLeftOutlined />,
          label: "Outlets Imports",
          onClick: () => navigate("/admin/outlets-import"),
        },
        {
          key: "targets",
          icon: <VerticalLeftOutlined />,
          label: "Sales Targets Imports",
          onClick: () => navigate("/admin/targets"),
        },
        {
          key: "events",
          icon: <VerticalLeftOutlined />,
          label: "Heads Up",
          onClick: () => navigate("/admin/heads-up"),
        },
        {
          key: "interaction-settings",
          icon: <VerticalLeftOutlined />,
          label: "Interaction Settings",
          onClick: () => navigate("/admin/interaction-settings"),
        },
        {
          key: "gps-corrections",
          icon: <VerticalLeftOutlined />,
          label: "GPS Corrections",
          onClick: () => navigate("/admin/gps-corrections"),
        },
        {
          key: "grading",
          icon: <VerticalLeftOutlined />,
          label: "Grading",
          onClick: () => navigate("/admin/grading"),
        },
        {
          key: "hierachy",
          icon: <VerticalLeftOutlined />,
          label: "Hierachy Management",
          onClick: () => navigate("/admin/hierarchy"),
        },
        {
          key: "knowledge-transfer",
          icon: <VerticalLeftOutlined />,
          label: "KT Modules",
          onClick: () => navigate("/admin/knowledge-transfer"),
        },
        {
          key: "media-files",
          icon: <VerticalLeftOutlined />,
          label: "Media Files",
          onClick: () => navigate("/admin/media-files"),
        },
        {
          key: "outlet-research",
          icon: <VerticalLeftOutlined />,
          label: "Outlet Research",
          onClick: () => navigate("/admin/outlet-research"),
        },
        {
          key: "outlets",
          icon: <VerticalLeftOutlined />,
          label: "Outlets",
          onClick: () => navigate("/admin/outlets"),
        },
        {
          key: "outlet-type",
          icon: <VerticalLeftOutlined />,
          label: "Outlet Type",
          onClick: () => navigate("/admin/outlet-type"),
        },
        {
          key: "phone-number",
          icon: <VerticalLeftOutlined />,
          label: "Phone Numbers",
          onClick: () => navigate("/admin/phone-numbers"),
        },
        {
          key: "pos-baskets",
          icon: <VerticalLeftOutlined />,
          label: "POS Baskets",
          onClick: () => navigate("/admin/pos-baskets"),
        },
        {
          key: "projects",
          icon: <VerticalLeftOutlined />,
          label: "Projects",
          onClick: () => navigate("/admin/projects"),
        },
        {
          key: "products",
          icon: <VerticalLeftOutlined />,
          label: "Products",
          onClick: () => navigate("/admin/products"),
        },
        {
          key: "product-settings",
          icon: <VerticalLeftOutlined />,
          label: "Product Settings",
          onClick: () => navigate("/admin/product-settings"),
        },
        {
          key: "red-flags-settings",
          icon: <VerticalLeftOutlined />,
          label: "Red Flags Settings",
          onClick: () => navigate("/admin/settings/red-flags"),
        },
        {
          key: "regions",
          icon: <VerticalLeftOutlined />,
          label: "Regions",
          onClick: () => navigate("/admin/regions"),
        },
        {
          key: "roles",
          icon: <VerticalLeftOutlined />,
          label: "Roles",
          onClick: () => navigate("/admin/roles"),
        },
        {
          key: "scorecards",
          icon: <VerticalLeftOutlined />,
          label: "Scorecards",
          onClick: () => navigate("/admin/scorecards"),
        },
        {
          key: "photo-audit",
          icon: <VerticalLeftOutlined />,
          label: "Photo Audit",
          onClick: () => navigate("/admin/photo-audit"),
        },
        {
          key: "axonify",
          icon: <VerticalLeftOutlined />,
          label: "Axonify",
          onClick: () => navigate("/admin/axonify"),
        },
        {
          key: "sku-baskets",
          icon: <VerticalLeftOutlined />,
          label: "SKU Baskets",
          onClick: () => navigate("/admin/sku-baskets"),
        },
        {
          key: "strategic-audit",
          icon: <VerticalLeftOutlined />,
          label: "Strategic Audits",
          onClick: () => navigate("/admin/strategic-audit"),
        },
        {
          key: "smart-start",
          icon: <VerticalLeftOutlined />,
          label: "Smart Start",
          onClick: () => navigate("/admin/smart-start"),
        },
        {
          key: "users",
          icon: <VerticalLeftOutlined />,
          label: "Users",
          onClick: () => navigate("/admin/users"),
        },
        {
          key: "geographical_hierarchy",
          icon: <VerticalLeftOutlined />,
          label: "Geographical Hierarchy",
          onClick: () => navigate("/admin/geographical_hierarchy"),
        },
        {
          key: "drop-off",
          icon: <VerticalLeftOutlined />,
          label: "Drop Off",
          onClick: () => navigate("/admin/dropoff"),
        },
        {
          key: "drop-off-reason",
          icon: <VerticalLeftOutlined />,
          label: "Drop Off Reason",
          onClick: () => navigate("/admin/dropoffreason"),
        },
        {
          key: "clients",
          icon: <VerticalLeftOutlined />,
          label: "Client",
          onClick: () => navigate("/admin/clients"),
        },
        {
          key: "outlet-state",
          icon: <VerticalLeftOutlined />,
          label: "Outlet State",
          onClick: () => navigate("/admin/outletstate"),
        },
      ],
    },
  ];

  const getItems = () => {
    adminMenuItems.push({
      key: "support",
      icon: <VerticalLeftOutlined />,
      label: "Support",
      sequence: 5,
      children: [
        {
          key: "support-ticket",
          icon: <VerticalLeftOutlined />,
          label: "Tickets",
          onClick: () => navigate("/admin/support/tickets"),
        },
      ],
    });

    adminMenuItems.push({
      key: "vip",
      icon: <VerticalLeftOutlined />,
      label: "VIP",
      sequence: 6,
      children: [
        {
          key: "vip-devices",
          icon: <VerticalLeftOutlined />,
          label: "Devices",
          onClick: () => navigate("/admin/devices"),
        },
        {
          key: "gps-spoofing-tracker",
          icon:<VerticalLeftOutlined />,
          label:"GPS Spoofing Tracker",
          onClick:() => navigate("/admin/gpsSpoofing")
        }
      ],
    });

    const visibleLayout = adminMenuItems;

    const sortedLayout = visibleLayout.sort((a, b) => a.sequence - b.sequence);

    visibleLayout.forEach((item) => {
      if (item.children && item.key != "instore-program") {
        item.children.sort((a, b) => a.label.localeCompare(b.label));
      }
    });

    if (access) {
      const filteredLayout = sortedLayout.filter((item) => {
        if (item.children) {
          const filteredChildren = item.children.filter((child) => {
            return access.includes(child.label);
          });
          item.children = filteredChildren;

          if (filteredChildren.length > 0) {
            return true;
          }
        }
      });
      return filteredLayout;
    }

    return sortedLayout;
  };

  const isProjectSelectVisible =
    role === "admin" ||
    auth.currentUser?.email === "gerrit.vanzyl@vcontractor.co.za" ||
    auth.currentUser?.email === "blaise.makuniata@publicis.co.za" ||
    auth.currentUser?.email === "Liza.kaplan@vodacom.co.za" ||
    auth.currentUser?.email === "liza.kaplan@vodacom.co.za" ||
    auth.currentUser?.email === "mpumelelo.khumalo@vodacom.co.za" ||
    !!role;
  //  === "Support Administrator" ||
  // role === "Training Administrator" ||
  // role === "Manager I";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Layout style={layoutStyles}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
          }}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            mode="vertical"
            defaultSelectedKeys={["1"]}
            items={getItems()}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: "flex", flexDirection: "row" }}
          >
            {collapsed ? (
              <MenuUnfoldOutlined {...collapsibleProps} />
            ) : (
              <MenuFoldOutlined {...collapsibleProps} />
            )}
            <div
              style={{
                padding: "0 10px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              <Avatar
                icon={<UserOutlined />}
                style={{
                  marginRight: "10px",
                }}
              />
              {`${email} [${role}]`}
            </div>
            <div style={{ padding: "0 10px", marginLeft: "auto" }}>
              {isProjectSelectVisible && (
                <Select
                  // defaultValue="lucy"
                  // onSearch={handleSearch}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch={true}
                  value={projectId}
                  style={{ width: 300 }}
                  onChange={(value,e) => handleChange(e)}
                  options={projectOptions}
                />
              )}
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={globalLogOutDispatch}
              >
                Logout
              </Button>
            </div>
          </Header>
          <Content style={{ padding: "16px", overflow: "auto" }}>
            {/* {props.children} */}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminDashboardLayout;
